import { useState, useEffect } from 'react'
import { Container } from './styles'
import Button from '@/components/Button'
import PlaygroundStatusTag from '@/pages/PlaygroundPage/components/PlaygroundStatusTag'
import getIconByUrl from '@/utils/getIconByUrl'
import IconBorder from '@/assets/images/playgrounds/jumpboxes/icon-border.svg'

const JumpboxCard = ({ jumpbox, onClick }) => {
  const [languageIcon, setLanguageIcon] = useState(null)
  const [interfaceIcon, setInterfaceIcon] = useState(null)

  useEffect(() => {
    if (!jumpbox) return

    getIconByUrl({
      iconUrl: jumpbox?.language?.logo_url || 'ant-FileTextOutlined',
      className: 'language-icon',
      onReady: setLanguageIcon,
    })

    getIconByUrl({
      iconUrl: jumpbox?.interface?.logo_url || 'ant-FileTextOutlined',
      className: 'interface-icon',
      onReady: setInterfaceIcon,
    })
  }, [jumpbox])

  return (
    <Container className="jumpbox-card-container" hasHover={!!onClick}>
      <div className="icons-container">
        <div className="icon-container">
          <img src={IconBorder} />
          {languageIcon}
        </div>

        <div className="icon-container">
          <img src={IconBorder} />
          {interfaceIcon}
        </div>

        {jumpbox?.status === 'draft' && <PlaygroundStatusTag status="draft" />}
      </div>

      <h4 className="title">{jumpbox?.name}</h4>

      <div
        className="description"
        dangerouslySetInnerHTML={{
          __html: jumpbox?.description_html,
        }}
      />

      {onClick && (
        <div className="hover-content">
          <div className="libs-container">
            <p className="title">Installed libraries</p>

            {!!jumpbox?.libraries?.length && (
              <div className="libs-box">
                {jumpbox?.libraries?.map((l) => (
                  <span key={l?.name} className="lib">
                    {l?.name}
                  </span>
                ))}
              </div>
            )}
          </div>

          <div className="bottom-actions">
            <Button className="select-jumpbox-btn" type="primary" size="large" onClick={() => onClick({ jumpbox })}>
              Select environment
            </Button>
          </div>
        </div>
      )}
    </Container>
  )
}

export default JumpboxCard
