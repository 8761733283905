import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Menu, Tag } from 'antd'
import { BuildOutlined } from '@ant-design/icons'
import { Board24Regular, PeopleCommunity24Regular, DataPie24Regular, AddSquare24Filled } from '@fluentui/react-icons'
import { isDown } from '@/themes/breakpoints'
import Layout1 from '@/layouts/Layout1'
import ShareModal from '@/components/ShareModal'
import OverviewTab from './components/OverviewTab'
import MyPlaygroundsTab from './components/MyPlaygroundsTab'
import NewPlaygroundTab from './components/NewPlaygroundTab'
import DataSourcesSearchTab from './components/DataSourcesSearchTab'
import WizardModal from './components/WizardModal'
import PlaygroundsFeedbackModal from '@/pages/PlaygroundsPage/components/PlaygroundsFeedbackModal'
import PlaygroundsFeedbackTag from '@/pages/PlaygroundsPage/components/PlaygroundsFeedbackTag'
import { toggleWizardModal, updateWizard } from '@/store/playgrounds/actions'
import { getDataSource } from '@/store/dataSources/actions'
import { PlaygroundsContainer, MenuContainer } from './styles'

const PlaygroundsPage = () => {
  const dispatch = useDispatch()
  let [searchParams, setSearchParams] = useSearchParams()

  const { theme } = useSelector((state) => state.app)
  const { items: jumpboxes } = useSelector((state) => state.jumpboxes)
  const { currentDataSource, error: dataSourcesError } = useSelector((state) => state.dataSources)

  const [selectedTab, setSelectedTab] = useState()

  const tab = searchParams.get('tab')
  const jumpboxId = searchParams.get('jumpbox')
  const dataSourceId = searchParams.get('data_source')

  const items = [
    { label: 'Overview', key: 'overview', icon: <Board24Regular />, title: '' },
    { label: 'My Playgrounds', key: 'my-playgrounds', icon: <BuildOutlined />, title: '' },
    {
      label: 'New Playground',
      key: 'new-playground',
      icon: <AddSquare24Filled />,
      className: 'new-playground-menu-item',
      title: '',
    },
    { label: 'Datasets', key: 'datasets', icon: <DataPie24Regular />, title: '' },
    {
      label: (
        <span className="has-tag">
          Community <Tag className="coming-soon-tag">coming soon</Tag>
        </span>
      ),
      key: 'community',
      icon: <PeopleCommunity24Regular />,
      title: '',
      disabled: true,
    },
  ]

  const renderContent = (tab) => {
    if (tab === 'overview') return <OverviewTab />
    if (tab === 'my-playgrounds') return <MyPlaygroundsTab />
    if (tab === 'new-playground') return <NewPlaygroundTab />
    if (tab === 'datasets') return <DataSourcesSearchTab />

    return <OverviewTab />
  }

  useEffect(() => {
    const isValidTab = ['overview', 'my-playgrounds', 'new-playground', 'datasets'].includes(tab)

    if (tab && tab !== selectedTab && isValidTab) {
      setSelectedTab(tab)
      return
    }

    if (!tab || !isValidTab) {
      setSelectedTab('overview')
    }
  }, [tab])

  useEffect(() => {
    if (!jumpboxId || !jumpboxes) return

    const jumpbox = jumpboxes?.find((j) => j?.id === jumpboxId)

    if (jumpbox) {
      dispatch(updateWizard({ jumpbox }))
      dispatch(toggleWizardModal(true))
    }

    searchParams.delete('jumpbox')
    setSearchParams(searchParams, { replace: true })
  }, [jumpboxId, jumpboxes])

  useEffect(() => {
    if (!dataSourceId) return

    if (!currentDataSource && !dataSourcesError) {
      dispatch(getDataSource(dataSourceId))
      return
    }

    if (currentDataSource) {
      dispatch(updateWizard({ preDataSource: currentDataSource }))
      dispatch(toggleWizardModal(true))
    }

    searchParams.delete('data_source')
    setSearchParams(searchParams, { replace: true })
  }, [dataSourceId, currentDataSource, dataSourcesError])

  useEffect(() => {
    if (!selectedTab) return

    setSearchParams({ ...Object.fromEntries(searchParams.entries()), tab: selectedTab }, { replace: true })
  }, [selectedTab])

  return (
    <Layout1 navbar>
      <PlaygroundsContainer className="playgrounds-page-container">
        <MenuContainer>
          <Menu
            inlineCollapsed={isDown('lg')}
            selectedKeys={[selectedTab]}
            defaultOpenKeys={['sub1']}
            mode="inline"
            theme={theme === 'dark' ? 'dark' : 'light'}
            items={items}
            onClick={({ key }) => setSelectedTab(key)}
          />
        </MenuContainer>

        {renderContent(selectedTab)}

        <PlaygroundsFeedbackTag />

        <WizardModal />
        <ShareModal contentType="playground" />
        <PlaygroundsFeedbackModal />
      </PlaygroundsContainer>
    </Layout1>
  )
}

export default PlaygroundsPage
