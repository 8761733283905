import styled, { css } from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => (props.isFinished ? props.theme.colors.cyan1 : props.theme.colors.gray1)};
  display: flex;
  flex-direction: column;
  border-left: 8px solid ${(props) => props.color || props.theme.colors.gray5};
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
  cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'pointer')};
  transition: 0.3s all;

  .title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .title {
      font-size: 12px;
      line-height: 15px;
      margin: 0px;
    }

    .icon {
      min-width: 18px;
      height: 18px;
      background: ${(props) => props.theme.colors.cyan3};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      > svg {
        font-size: 9px;
      }
    }
  }

  .progress-container {
    max-width: 260px;
    display: flex;
    gap: 8px;
    margin-bottom: -6px;

    .progress-text {
      color: ${(props) => props.theme.colors.base.gray7};
      font-size: 10px;
      margin: 0px;
    }

    .ant-progress {
      .ant-progress-inner {
        height: 6px;
        background-color: ${(props) => props.theme.colors.base.geekblue2};
      }

      .ant-progress-bg {
        height: 6px !important;
        background-color: ${(props) => props.theme.colors.base.geekblue4};
      }
    }
  }

  &:hover {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
  }

  ${(props) =>
    props.isDraft &&
    css`
      .title {
        color: ${(props) => props.theme.colors.gray6};
      }
    `}
`
