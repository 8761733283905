import axios from 'axios'
import {
  SET_WIZARD_UPDATED,
  TOGGLE_WIZARD_MODAL,
  RESET_WIZARD_STATE,
  SET_PLAYGROUNDS_LOADING,
  MY_PLAYGROUNDS_UPDATED,
  PUBLISHED_PLAYGROUNDS_UPDATED,
  ARCHIVED_PLAYGROUNDS_UPDATED,
  FEATURED_PLAYGROUNDS_UPDATED,
  PLAYGROUND_UPDATED,
  ALLOCATED_PLAYGROUNDS_UPDATED,
  PLAYGROUND_PREVIEW_UPDATED,
  SET_PLAYGROUND_PREVIEW_LOADING,
  SET_PLAYGROUND_PUBLISH_LOADING,
  SET_PLAYGROUND_VOTE_LOADING,
  PLAYGROUND_MODE_UPDATED,
  INFO_SIDE_DRAWER_UPDATED,
  TOGGLE_MULTIPLE_PLAYGROUNDS_MODAL,
  TOGGLE_PUBLISH_MODAL,
  SET_SHARING_MODAL_OPEN,
  SET_RESET_MODAL_OPEN,
  TOGGLE_FEEDBACK_MODAL,
  RESET_PLAYGROUNDS_STATE,
  SET_PLAYGROUNDS_ERROR,
} from '@/store/playgrounds'
import {
  fetchPlaygrounds,
  fetchPlayground,
  postPlayground,
  patchPlayground,
  fetchAllocatedPlaygrounds,
  postPlaygroundPreview,
  postPlaygroundPublish,
  postPlaygroundVote,
  deletePlaygroundVote,
  postPlaygroundImage,
} from '@/services/playgrounds'
import { showToast } from '@/utils/toast'

const updateWizard = (data) => async (dispatch, getState) => {
  const { playgrounds } = getState()
  const { wizard } = playgrounds

  try {
    dispatch(SET_WIZARD_UPDATED({ ...wizard, ...data }))
  } catch (error) {
    const { message } = error
    dispatch(SET_WIZARD_UPDATED({ ...wizard, error: message }))
  }
}

const toggleWizardModal = (isOpen) => (dispatch) => {
  dispatch(TOGGLE_WIZARD_MODAL(isOpen))
}

const resetWizard = () => async (dispatch, getState) => {
  const { wizard } = getState().playgrounds

  try {
    dispatch(RESET_WIZARD_STATE())
  } catch (error) {
    const { message } = error
    dispatch(SET_WIZARD_UPDATED({ ...wizard, error: message }))
  }
}

const getPlaygrounds = (params) => async (dispatch) => {
  try {
    dispatch(SET_PLAYGROUNDS_LOADING(true))

    const playgrounds = await fetchPlaygrounds(params)

    if ('featured' in params) {
      dispatch(FEATURED_PLAYGROUNDS_UPDATED(playgrounds?.results))
      return
    }

    if ('status' in params && params?.['status'] === 'published') {
      dispatch(PUBLISHED_PLAYGROUNDS_UPDATED(playgrounds?.results))
      return
    }

    if ('status' in params && params?.['status'] === 'archived') {
      dispatch(ARCHIVED_PLAYGROUNDS_UPDATED(playgrounds?.results))
      return
    }

    // TODO: "Created with this dataset"
    // GET /api/v1/playgrounds?data_source=<dataSourceId>

    if ('user' in params) {
      dispatch(MY_PLAYGROUNDS_UPDATED(playgrounds?.results))
      return
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
  } finally {
    dispatch(SET_PLAYGROUNDS_LOADING(false))
  }
}

const getPlayground = (playgroundId, cb) => async (dispatch) => {
  try {
    dispatch(SET_PLAYGROUNDS_LOADING(true))

    const playground = await fetchPlayground(playgroundId)
    dispatch(PLAYGROUND_UPDATED(playground))

    if (playground?.preview_html) {
      const previewHtml = await axios({
        method: 'GET',
        url: playground?.preview_html,
      })
      dispatch(PLAYGROUND_PREVIEW_UPDATED(previewHtml?.data))
    } else {
      dispatch(PLAYGROUND_PREVIEW_UPDATED(null))
    }

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
  } finally {
    dispatch(SET_PLAYGROUNDS_LOADING(false))
  }
}

const createPlayground = (data, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUNDS_LOADING(true))

    const { playgrounds } = getState()
    const { myPlaygrounds } = playgrounds

    const playground = await postPlayground(data)

    if (!playground?.metadata?.account_id) {
      dispatch(MY_PLAYGROUNDS_UPDATED([playground, ...myPlaygrounds]))
    }

    if (cb) {
      cb(playground)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_PLAYGROUNDS_LOADING(false))
  }
}

const updatePlayground = (playgroundId, data, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUNDS_LOADING(true))

    const { playgrounds } = getState()
    const { currentPlayground } = playgrounds

    await patchPlayground(playgroundId, data)

    if (currentPlayground) {
      dispatch(PLAYGROUND_UPDATED({ ...currentPlayground, ...data }))
    }

    // clean lists to force reload
    dispatch(MY_PLAYGROUNDS_UPDATED(null))
    dispatch(PUBLISHED_PLAYGROUNDS_UPDATED(null))
    dispatch(ARCHIVED_PLAYGROUNDS_UPDATED(null))

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_PLAYGROUNDS_LOADING(false))
  }
}

const getAllocatedPlaygrounds = () => async (dispatch) => {
  try {
    const playgrounds = await fetchAllocatedPlaygrounds()
    dispatch(ALLOCATED_PLAYGROUNDS_UPDATED(playgrounds))
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
  }
}

const createPlaygroundPreview = (playgroundId) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUND_PREVIEW_LOADING(true))

    const { playgrounds } = getState()
    const { currentPlayground } = playgrounds

    const playgroundPreviewData = await postPlaygroundPreview(playgroundId)

    const previewHtml = await axios({
      method: 'GET',
      url: playgroundPreviewData?.preview_html,
    })

    dispatch(PLAYGROUND_UPDATED({ ...currentPlayground, preview_html: playgroundPreviewData?.preview_html }))
    dispatch(PLAYGROUND_PREVIEW_UPDATED(previewHtml?.data))
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
  } finally {
    dispatch(SET_PLAYGROUND_PREVIEW_LOADING(false))
  }
}

const setPlaygroundPreview = (playgroundPreview) => (dispatch) => {
  dispatch(PLAYGROUND_PREVIEW_UPDATED(playgroundPreview))
}

const createPlaygroundPublish = (playgroundId, data) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUND_PUBLISH_LOADING(true))

    const { playgrounds } = getState()
    const { currentPlayground } = playgrounds

    const newVersion = await postPlaygroundPublish(playgroundId, data)

    const updatedPlayground = {
      ...currentPlayground,
      status: 'published',
      versions: [...(currentPlayground?.versions || []), newVersion],
    }

    dispatch(PLAYGROUND_UPDATED(updatedPlayground))

    return newVersion
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_PLAYGROUND_PUBLISH_LOADING(false))
  }
}

const createPlaygroundVote = (playgroundId, data) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUND_VOTE_LOADING(true))

    const { playgrounds } = getState()
    const { featuredPlaygrounds } = playgrounds

    const votes = await postPlaygroundVote(playgroundId, data)

    const updatedPlaygrounds = featuredPlaygrounds.map((p) => {
      const auxPlayground = { ...p }

      if (auxPlayground.id === playgroundId) {
        auxPlayground.votes = votes
        auxPlayground.user_status = { ...auxPlayground.user_status, vote: 'upvote' }
      }

      return auxPlayground
    })

    dispatch(FEATURED_PLAYGROUNDS_UPDATED(updatedPlaygrounds))
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
  } finally {
    dispatch(SET_PLAYGROUND_VOTE_LOADING(false))
  }
}

const removePlaygroundVote = (playgroundId, data) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUND_VOTE_LOADING(true))

    const { playgrounds } = getState()
    const { featuredPlaygrounds } = playgrounds

    const votes = await deletePlaygroundVote(playgroundId, data)

    const updatedPlaygrounds = featuredPlaygrounds.map((p) => {
      const auxPlayground = { ...p }

      if (auxPlayground.id === playgroundId) {
        auxPlayground.votes = votes
        auxPlayground.user_status = {
          ...auxPlayground.user_status,
          vote: null,
        }
      }

      return auxPlayground
    })

    dispatch(FEATURED_PLAYGROUNDS_UPDATED(updatedPlaygrounds))
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
  } finally {
    dispatch(SET_PLAYGROUND_VOTE_LOADING(false))
  }
}

const createPlaygroundImage = (file, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUNDS_LOADING(true))

    const { wizard } = getState().playgrounds

    const response = await postPlaygroundImage(file)

    dispatch(SET_WIZARD_UPDATED({ ...wizard, imageUrl: response?.url }))

    showToast('Playground image was updated.')

    if (cb) {
      cb({ image_url: response?.url })
    }
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_PLAYGROUNDS_LOADING(false))
  }
}

const updatePlaygroundMode = (mode) => (dispatch) => {
  dispatch(PLAYGROUND_MODE_UPDATED(mode))
}

const selectInfoSideDrawerItem = (newPage) => (dispatch) => {
  dispatch(INFO_SIDE_DRAWER_UPDATED(newPage))
}

const toggleMultiplePlaygroundsModal = (isOpen) => (dispatch) => {
  dispatch(TOGGLE_MULTIPLE_PLAYGROUNDS_MODAL(isOpen))
}

const togglePublishModal = (isOpen) => (dispatch) => {
  dispatch(TOGGLE_PUBLISH_MODAL(isOpen))
}

const setSharingModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_SHARING_MODAL_OPEN(isOpen))
}

const setResetModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_RESET_MODAL_OPEN(isOpen))
}

const toggleFeedbackModal = (isOpen) => (dispatch) => {
  dispatch(TOGGLE_FEEDBACK_MODAL(isOpen))
}

const resetPlaygroundsState = () => (dispatch) => {
  dispatch(RESET_PLAYGROUNDS_STATE())
}

export {
  updateWizard,
  toggleWizardModal,
  resetWizard,
  getPlaygrounds,
  getPlayground,
  createPlayground,
  updatePlayground,
  createPlaygroundPreview,
  setPlaygroundPreview,
  createPlaygroundPublish,
  createPlaygroundVote,
  removePlaygroundVote,
  createPlaygroundImage,
  getAllocatedPlaygrounds,
  updatePlaygroundMode,
  selectInfoSideDrawerItem,
  toggleMultiplePlaygroundsModal,
  togglePublishModal,
  setSharingModalOpen,
  setResetModalOpen,
  toggleFeedbackModal,
  resetPlaygroundsState,
}
