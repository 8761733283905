import styled from 'styled-components'

export const Content = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.geekblue1};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => (props.isPro ? 'justify-content: center;' : 'justify-content: space-between;')}
  padding: 30px;

  .info-container {
    width: 100%;
    max-width: 540px;
    ${(props) => !props.isPro && 'flex: 1;'}
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .progress-title {
      margin-bottom: 30px;
    }

    .title {
      color: ${(props) => props.theme.colors.gray13};
    }

    .subtitle {
      color: ${(props) => props.theme.colors.gray8} !important;
    }

    .device-loading-container {
      width: 100%;
      margin: 30px 0px 60px;

      .device-loading-progress {
        margin-bottom: 20px;

        .device-name {
          color: ${(props) => props.theme.colors.gray11};
          margin: 0px;
        }

        .progress-container {
          display: flex;

          .ant-progress-text {
            color: ${(props) => props.theme.colors.text};
          }
          .restart-btn {
            background: transparent;
            margin-left: 8px;
          }
        }

        .error-msg {
          color: ${(props) => props.theme.colors.base.red4};
          display: block;
          position: relative;
          margin-top: -4px;
        }
      }
    }
  }

  .error-box {
    max-width: 480px;
    background-color: ${(props) => props.theme.colors.red1};
    color: ${(props) => props.theme.colors.gray9};
    border: 1px solid ${(props) => props.theme.colors.red2};
    border-radius: 2px;
    line-height: 22px;
    padding: 9px 16px;
  }

  .quote-container {
    height: 100px;
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    margin-top: 40px;

    img {
      width: 72px;
      opacity: 0.08;
      position: absolute;
      top: -20px;
      left: -30px;
    }

    h5 {
      color: ${(props) => props.theme.colors.gray13};
      font-style: italic;
    }
  }
`
