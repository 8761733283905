import { useSelector, useDispatch } from 'react-redux'
import { Skeleton } from 'antd'
import { WarningOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import Button from '@/components/Button'
import FadeIn from '@/components/FadeIn'
import ErrorCard from '@/pages/ErrorPage/components/ErrorCard'
import DeviceContent from '@/pages/ModulePage/components/DeviceContent'
import AllocatedModules from '@/pages/ModulePage/components/AllocatedModules'
import HTMLBlock from '@/components/HTMLBlock'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { Container } from './styles'

const PlaygroundContent = ({ canLaunchPlayground, tryAllocateLab }) => {
  const dispatch = useDispatch()

  const {
    currentPlayground: playground,
    playgroundMode,
    playgroundPreview,
    allocatedPlaygrounds,
    isPlaygroundPreviewLoading,
    isLoading: isPlaygroundLoading,
    error: playgroundsError,
  } = useSelector((state) => state.playgrounds)
  const { currentLab: lab, isLabLoading, error: labsError } = useSelector((state) => state.labs)

  const isCustomProject = !!playground?.metadata?.account_id
  const labSession = lab?.allocated_session
  const isDeviceStopping = labSession?.devices?.some((d) => d.status === 'stopping')
  const isLabSessionAllocated = labSession?.status === 'allocated'

  const renderPreviewContent = () => {
    if (playgroundPreview) {
      return <HTMLBlock className="playground-preview" content={playgroundPreview} />
    }
  }

  const renderEditContent = () => {
    return (
      <>
        <div className="mobile-message">
          <WarningOutlined />
          <div>
            <p>Our Playgrounds are optimized for Desktop environments.</p>
            <p>Mobile is supported but the experience is limited.</p>
          </div>
        </div>

        {labSession && <DeviceContent hideHeader />}
      </>
    )
  }

  if (!labSession && (playgroundsError || labsError)) {
    return (
      <Container className="playground-content">
        <FadeIn>
          <ErrorCard
            title={
              playgroundsError === 'Not found.'
                ? `${isCustomProject ? 'Project' : 'Playground'} not found`
                : `There was an error loading this ${isCustomProject ? 'project' : 'playground'}`
            }
            text={`${labsError || playgroundsError || `Problem ocurred while loading ${isCustomProject ? 'project' : 'playground'}.`} If the error persists, please contact support.`}
            primaryButtonText={isCustomProject ? 'Go to Dasboard' : 'Go to Playgrounds'}
            primaryButtonLinkTo={isCustomProject ? '/dashboard' : '/playgrounds'}
            buttonText={'Retry'}
            buttonOnClick={() => window.location.reload()}
          />
        </FadeIn>
      </Container>
    )
  }

  if (
    (!isCustomProject || (isCustomProject && !isPlaygroundPreviewLoading)) &&
    (isPlaygroundLoading || isLabLoading || isPlaygroundPreviewLoading || isDeviceStopping || !playground || !lab)
  ) {
    return (
      <Container className="playground-content">
        <FadeIn>
          <div className="playground-preview loading-content">
            {!isCustomProject && (isPlaygroundPreviewLoading || isDeviceStopping) && (
              <>
                <h4 className="title">Generating {isCustomProject ? 'Project' : 'Playground'} preview...</h4>
                <p className="description">This could take a few seconds...</p>
              </>
            )}

            <Skeleton active title={false} paragraph={{ rows: 4 }} />
            <Skeleton active title={false} paragraph={{ rows: 6 }} />
            <Skeleton active title={false} paragraph={{ rows: 2 }} />
            <Skeleton active title={false} paragraph={{ rows: 7 }} />
          </div>
        </FadeIn>
      </Container>
    )
  }

  return (
    <Container className="playground-content">
      <FadeIn>
        {playgroundMode === 'edit' ? renderEditContent() : renderPreviewContent()}

        {((playgroundMode === 'edit' && !labSession) || (playgroundMode === 'preview' && !playgroundPreview)) && (
          <div className="playground-preview">
            <div className="no-preview-content">
              <ExclamationCircleFilled className="icon" />

              {playgroundMode === 'edit' && !canLaunchPlayground ? (
                <>
                  <h3 className="title">You are already running other {isCustomProject ? 'Project' : 'Playground'}</h3>

                  <p className="description">
                    Keep working on the running {isCustomProject ? 'Project' : 'Playground'} or stop it to start a new
                    one.
                  </p>

                  <AllocatedModules allocatedModules={allocatedPlaygrounds} />

                  <p className="description">
                    Want to run multiple {isCustomProject ? 'Projects' : 'Playgrounds'} simultaneously?
                  </p>

                  <Button
                    type="primary"
                    size="large"
                    ispro="true"
                    onClick={() => dispatch(toggleUpgradeToProModal(true))}
                  >
                    Upgrade to PRO
                  </Button>
                </>
              ) : (
                <>
                  <h3 className="title">Preview not available</h3>

                  <p className="description">
                    This {isCustomProject ? 'project' : 'playground'} doesn't have a preview yet. Start it, make changes
                    and it'll be generated automatically.
                  </p>
                </>
              )}

              {playgroundMode === 'preview' && (
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    const editModeBtn = document.getElementById('playground-edit-mode-btn')
                    editModeBtn?.click()
                  }}
                >
                  {isLabSessionAllocated ? 'Continue editing' : 'Start editing'}
                </Button>
              )}

              {playgroundMode === 'edit' && canLaunchPlayground && (
                <Button type="primary" size="large" onClick={() => tryAllocateLab()}>
                  Start editing
                </Button>
              )}
            </div>
          </div>
        )}
      </FadeIn>
    </Container>
  )
}

export default PlaygroundContent
