'use client'

import styled from 'styled-components'
import { Modal as AntForm } from 'antd'
import { device } from '@/themes/breakpoints'

export const Modal = styled(AntForm)`
  .ant-modal-content {
    height: 100% !important;
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray3} !important;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0px !important;

    .ant-modal-body {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .left-content,
  .right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;

    .content {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .logo {
        margin-bottom: 30px;
      }

      > .title {
        max-width: 330px;
        color: ${(props) => props.theme.colors.base.gray1};
        margin: 0px auto 30px;
      }

      > .description {
        max-width: 440px;
        color: ${(props) => props.theme.colors.base.gray1};
        margin: 0px auto;
      }

      > .image {
        height: auto;
        max-width: 450px;
        width: 100%;
        margin-bottom: 30px;
        box-shadow: 0px 3px 24px 8px rgba(0, 0, 0, 0.3);
      }

      > .title,
      > .description,
      > .image {
        display: none;
      }
    }
  }

  .left-content {
    background-size: cover;
    background-position: center;
    object-fit: cover;

    @media ${device.lgUp} {
      align-items: flex-end;

      .content {
        margin: 0px 16% 0px 4%;
      }
    }
  }

  .right-content {
    .content {
      width: 100%;
      max-width: 420px;
    }

    @media ${device.lgUp} {
      align-items: flex-start;

      .content {
        margin: 0px 4% 0px 16%;
      }
    }
  }

  @media ${device.lgUp} {
    .ant-modal-content {
      .ant-modal-body {
        flex-direction: row;
      }
    }

    .left-content,
    .right-content {
      width: 50%;
      padding: 60px 20px;

      .title,
      .description,
      .image {
        display: block !important;
      }
    }
  }
`
