import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
  background-color: ${(props) => props.theme.colors.gray3};
  color: ${(props) => props.theme.colors.gray10};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  .anticon-check svg {
    font-size: 12px;
  }

  .ant-spin {
    font-size: 0px;
  }

  ${(props) =>
    props.size === 'sm' &&
    css`
      width: 14px;
      min-width: 14px;
      height: 14px;

      > .anticon-check svg {
        font-size: 8px;
      }
    `}

  ${(props) =>
    props.size === 'lg' &&
    css`
      width: 32px;
      min-width: 32px;
      height: 32px;

      > .anticon-check svg {
        font-size: 14px;
      }
    `}

  ${(props) =>
    props.status === 'finished' &&
    css`
      background-color: ${props.theme.colors.cyan3};
    `}

  ${(props) =>
    props.skillStatus === 'coming_soon' &&
    css`
      > .anticon-check svg {
        color: ${props.theme.colors.gray6};
      }
    `}
`
