import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Tooltip, Tag, Button as AntdButton } from 'antd'
import { CodeOutlined, BulbOutlined, EyeOutlined, BugOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import CodeEditor from '@/components/CodeEditor'
import Replacement from '@/pages/ModulePage/components/Replacement'
import ActivityReactions from '@/pages/ModulePage/components/Activity/components/ActivityReactions'
import { toggleAuthModal } from '@/store/users/actions'
import {
  createActivityAttempt,
  createActivityHint,
  createActivitySolution,
  toggleReportIssueModal,
} from '@/store/activities/actions'

const CodeActivity = ({ activity, isReview, isActivityAllowed }) => {
  const dispatch = useDispatch()

  const { uiConfig } = useSelector((state) => state.app)
  const { isAuthenticated, userProfile } = useSelector((state) => state.users)
  const { isLoading: isActivityLoading } = useSelector((state) => state.activities)
  const { currentModule } = useSelector((state) => state.modules)
  const { currentLab: lab, currentViewIsLoaded } = useSelector((state) => state.labs)
  const hasActiveLabSession = !!lab?.allocated_session

  const [answer, setAnswer] = useState()
  const [infoBlock, setInfoBlock] = useState()

  const shouldShowCodeActivityAssertions = userProfile?.permissions?.includes('content.view_code_activity_assertions')
  const canViewEarlyAccess = userProfile?.permissions?.includes('content.module_early_access')
  const canExecute = canViewEarlyAccess || !currentModule?.early_access

  const shouldViewActivitySolutions = currentModule?.user_status?.last_module_attempt?.config?.show_solutions

  const moduleAttempt = currentModule?.user_status?.last_module_attempt
  const isAttemptActive = moduleAttempt?.is_active

  const isActivitySubmitted = !!activity.user_status?.submitted_answer_at
  const answerSubmittedAt = activity.user_status?.submitted_answer_at

  const isActivityPassed = activity.user_status?.is_passed
  const isActivityReCheckPassed = activity?.user_status?.grading
    ? activity?.user_status?.grading?.is_passed
    : activity.user_status?.is_passed
  const activityStdout = activity?.user_status?.grading
    ? activity?.user_status?.grading?.grade_output?.stdout
    : activity.user_status?.submitted_answer?.stdout
  const activityError = activity?.user_status?.grading
    ? activity?.user_status?.grading?.grade_output?.stderr
    : activity.user_status?.submitted_answer?.stderr

  const solutionRequested = activity.user_status?.solution_requested
  const solutionRequestedAt = solutionRequested && activity.user_status?.solution_requested_at
  const isSolutionRequestedBeforeSubmission =
    solutionRequested && dayjs(solutionRequestedAt).isBefore(dayjs(answerSubmittedAt))

  const hintRequested = activity.user_status?.hint_requested
  const hintRequestedAt = hintRequested && activity.user_status?.hint_requested_at
  const isHintRequestedBeforeSubmission = hintRequested && dayjs(hintRequestedAt).isBefore(dayjs(answerSubmittedAt))

  const handleSubmitActivity = () => {
    if (!isAuthenticated) {
      dispatch(toggleAuthModal(true))
      return
    }

    const data = {
      activityId: activity.id,
      submittedAnswer: answer,
    }

    dispatch(createActivityAttempt(data))
  }

  const handleRequestInstructorCode = () => {
    if (activity?.instructor_code) {
      setInfoBlock(infoBlock === 'instructor-code' ? null : 'instructor-code')
    }
  }

  const handleRequestHint = () => {
    if (activity?.hint_html) {
      setInfoBlock(infoBlock === 'hint' ? null : 'hint')
      return
    }

    const data = {
      activityId: activity.id,
    }
    dispatch(createActivityHint(data))
    setInfoBlock('hint')
  }

  const handleRequestSolution = () => {
    if (!shouldViewActivitySolutions) return

    if (activity?.solution_html) {
      setInfoBlock(infoBlock === 'solution' ? null : 'solution')
      return
    }

    const data = {
      activityId: activity.id,
    }
    dispatch(createActivitySolution(data))
    setInfoBlock('solution')
  }

  const activityActions = (
    <div className="activity-actions">
      {shouldShowCodeActivityAssertions && activity?.instructor_code && !isReview && (
        <Tooltip title="View instructor code">
          <AntdButton
            className={`instructor-code-btn ${infoBlock === 'instructor-code' && 'is-selected'}`}
            shape="circle"
            icon={<CodeOutlined />}
            onClick={handleRequestInstructorCode}
            loading={isActivityLoading && isActivityLoading === activity?.id}
            disabled={isActivityLoading || !canExecute}
          />
        </Tooltip>
      )}

      {activity?.has_hint && (!isReview || (isReview && activity?.hint_html && isHintRequestedBeforeSubmission)) && (
        <Tooltip title={isAttemptActive ? 'View hint' : 'Start project to reveal hint'}>
          <AntdButton
            className={`hint-btn ${infoBlock === 'hint' && 'is-selected'}`}
            shape="circle"
            icon={<BulbOutlined />}
            onClick={handleRequestHint}
            loading={isActivityLoading && isActivityLoading === activity?.id}
            disabled={!isAttemptActive || isActivityLoading || !canExecute}
          />
        </Tooltip>
      )}

      {activity?.has_solution &&
        shouldViewActivitySolutions &&
        (!isReview || (isReview && activity?.solution_html && isSolutionRequestedBeforeSubmission)) && (
          <Tooltip title={isAttemptActive ? 'View solution' : 'Start project to reveal solution'}>
            <AntdButton
              className={`solution-btn ${infoBlock === 'solution' && 'is-selected'} ${
                !currentModule?.show_solutions && 'danger'
              }`}
              shape="circle"
              icon={<EyeOutlined />}
              onClick={handleRequestSolution}
              loading={isActivityLoading && isActivityLoading === activity?.id}
              disabled={!isAttemptActive || isActivityLoading || !canExecute}
            />
          </Tooltip>
        )}
    </div>
  )

  // TODO: don't keep using isReview as we now have Teams
  const getSubmitInfoText = () => {
    if (!isAttemptActive || !hasActiveLabSession || !currentViewIsLoaded)
      return 'Start project before checking your activities.'

    if (!isReview && !isActivityAllowed) return 'Resolve the previous activity first.'

    if (!canExecute) return 'This project is currenly on Early Access.'

    if (hasActiveLabSession && activity?.code_input && !answer)
      return 'Write your code in the input above before checking activity.'
  }

  useEffect(() => {
    setAnswer(activity?.user_status?.submitted_answer?.code_answer || '')
  }, [activity?.user_status])

  return (
    <>
      <div className="activity-container code-activity">
        <div className="activity-content">
          {activity?.custom_number && <Tag className="activity-tag">{activity?.custom_number}</Tag>}

          <div className="activity-body">
            {!activity?.custom_number && <span className="activity-number">{(activity?.order || 0) + 1}</span>}

            {activity?.title_html && (
              <div className="title-container" dangerouslySetInnerHTML={{ __html: activity?.title_html }} />
            )}

            {!isReview && isAuthenticated && activityActions}
          </div>

          <Replacement contentHtml={activity?.content_html} customClass={'activity-content-markdown'} />

          {activity?.expected_outcome_html && (
            <div className="activity-expected-outcome-container">
              <p className="section-title">Expected outcome:</p>
              <Replacement contentHtml={activity?.expected_outcome_html} customClass={'activity-expected-outcome'} />
            </div>
          )}
        </div>
      </div>

      {activity?.code_input && (
        <CodeEditor
          language={activity?.code_language}
          value={answer}
          setValue={setAnswer}
          placeholder={activity?.code_placeholder}
        />
      )}

      <div className="review-status-block">
        <span>
          {!isReview && (
            <Button
              type="primary"
              onClick={handleSubmitActivity}
              loading={isActivityLoading && isActivityLoading === activity?.id}
              disabled={
                !hasActiveLabSession ||
                !currentViewIsLoaded ||
                (activity?.code_input && !answer) ||
                !isActivityAllowed ||
                !isAttemptActive ||
                isActivityLoading ||
                !canExecute
              }
            >
              {isActivityPassed ? 'Recheck Activity' : 'Check Activity'}
            </Button>
          )}

          {isActivitySubmitted && (
            <span className={`activity-status-btn ${isActivityReCheckPassed ? 'correct' : 'incorrect'}`}>
              {isActivityLoading ? null : isActivityReCheckPassed ? 'Correct!' : 'Wrong answer, try again!'}
            </span>
          )}

          <p className="submit-info">{getSubmitInfoText()}</p>
        </span>

        {!isReview && activityError && <pre className="info-block error">{activityError}</pre>}

        {!isReview && activityStdout && <pre className="info-block out">{activityStdout}</pre>}

        {shouldShowCodeActivityAssertions &&
          !isReview &&
          activity?.instructor_code &&
          infoBlock === 'instructor-code' && (
            <pre className="info-block" dangerouslySetInnerHTML={{ __html: activity?.instructor_code }} />
          )}

        {!isReview && activity?.hint_html && infoBlock === 'hint' && (
          <div className="info-block">
            <Replacement contentHtml={activity?.hint_html} />
          </div>
        )}

        {!isReview && activity?.solution_html && shouldViewActivitySolutions && infoBlock === 'solution' && (
          <div className="info-block">
            <Replacement contentHtml={activity?.solution_html} />
          </div>
        )}

        {isReview && !isActivitySubmitted && (
          <Button type="dashed" className={'activity-status-btn not-actioned'}>
            Not actioned
          </Button>
        )}

        {isReview && activityActions}

        <ActivityReactions activity={activity} />

        {uiConfig?.showModuleReportIssue && isAuthenticated && isAttemptActive && (
          <div className="feedback-box">
            <div className="feedback-btn" onClick={() => dispatch(toggleReportIssueModal(activity))}>
              <BugOutlined />
              <span>Report issue</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CodeActivity
