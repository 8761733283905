import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.geekblue1} !important;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > img {
    height: 260px;
  }

  > .title {
    margin-bottom: 6px;
  }

  > .text-body {
    max-width: 330px;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
  }
`
