import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;

  .current-streak {
    background-color: ${(props) => props.theme.colors.geekblue2};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0px 4px 0px 8px;

    > h5 {
      color: ${(props) => props.theme.colors.gray13};
      margin: 0px;
    }

    > svg {
      width: 20px;

      ${(props) =>
        !props.hasStreak &&
        css`
          path:nth-child(1) {
            fill: #85a5ff;
          }

          path:nth-child(2) {
            fill: #d6e4ff;
          }
        `}
    }
  }
`
