import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 250px;
  height: 292px;
  background: ${(props) => props.theme.colors.gray1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 14px;
  overflow: hidden;
  padding: 15px 20px;
  box-shadow: 0px 1px 9px 2px rgba(92, 136, 223, 0.12);

  .img-container {
    min-height: 112px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${(props) =>
    props.isModule &&
    css`
      .img-container {
        background-color: ${(props) => props.theme.colors.geekblue1};

        img {
          width: 84px;
          opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
          object-fit: cover;
          pointer-events: none;
        }
      }
    `}

  ${(props) =>
    props.isPlayground &&
    css`
      .img-container {
        background: linear-gradient(250deg, ${props.theme.colors.geekblue4} 0%, ${props.theme.colors.gray12} 100%);

        .main-icon {
          width: 45px;
          height: 45px;
          color: ${(props) => props.theme.colors.gray1};
          font-size: 45px;
        }

        ${props.playgroundBackgroundImage &&
        css`
          background-image: url(${(props) => props.playgroundBackgroundImage});
          background-size: cover;
          background-position: center;
          object-fit: cover;

          .main-icon {
            display: none;
          }
        `}
      }
    `}

  .project-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 12px;

    .title {
      color: ${(props) => props.theme.colors.gray10};
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin: 0px;
    }

    .actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .text {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 12px;
        margin: 0px;
      }

      .link {
        width: 100%;
      }

      .cta-button {
        width: 100%;
      }
    }
  }

  .comments-info {
    width: 36px;
    height: 36px;
    background-color: ${(props) => props.theme.colors.geekblue3};
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 16px;

    > svg {
      width: 20px;
      height: 20px;
    }
  }

  @media ${device.mdDown} {
    .project-info {
      .title {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`
