import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { RefinementList } from 'react-instantsearch'
import { Collapse, Skeleton } from 'antd'
import ModuleUserStatusFilter from './ModuleUserStatusFilter'
import { Container } from './styles'

const LabFilters = ({ results, status: hitsStatus, indexUiState, setIndexUiState, refine, setPage }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  let [searchParams, setSearchParams] = useSearchParams()

  const learningArea = searchParams.get('learning_area')
  const skillTrack = searchParams.get('skill_track')
  const skill = searchParams.get('skill')
  const status = searchParams.get('status')
  const difficulty = searchParams.get('difficulty')
  const labType = searchParams.get('lab_type')
  const tags = searchParams.get('tags')

  const { isCatalogLoading } = useSelector((state) => state.modules)

  useEffect(() => {
    if (isLoading || !indexUiState?.configure) return

    if (isFirstLoad) {
      setIndexUiState((prevIndexUiState) => ({
        ...prevIndexUiState,
        refinementList: {
          ...prevIndexUiState.refinementList,
          'learning_area.name': learningArea?.split(',') || '',
          'skill_track.name': skillTrack?.split(',') || '',
          'skill.name': skill?.split(',') || '',
          status: status?.split(',') || '',
          difficulty: difficulty?.split(',') || '',
          module_type: labType?.split(',') || '',
          'tags.name': tags?.split(',') || '',
        },
      }))

      setIsFirstLoad(false)
      return
    }

    if (!indexUiState?.refinementList) return

    // update URL query params
    const validFilters = [
      'learning_area.name',
      'skill_track.name',
      'skill.name',
      'status',
      'difficulty',
      'module_type',
      'tags.name',
    ]
    const uiFilters = Object.keys(indexUiState?.refinementList)
    const uiFilterValues = Object.values(indexUiState?.refinementList)
    const newUrlQueryParams = {}

    validFilters?.forEach((filter) => {
      const i = uiFilters?.indexOf(filter)
      const value = i >= 0 && uiFilterValues?.[i]?.join(',')

      if (!value) return

      if (filter === 'learning_area.name') newUrlQueryParams['learning_area'] = value
      if (filter === 'skill_track.name') newUrlQueryParams['skill_track'] = value
      if (filter === 'skill.name') newUrlQueryParams['skill'] = value
      if (filter === 'status') newUrlQueryParams['status'] = value
      if (filter === 'difficulty') newUrlQueryParams['difficulty'] = value
      if (filter === 'module_type') newUrlQueryParams['lab_type'] = value
      if (filter === 'tags.name') newUrlQueryParams['tags'] = value
    })

    setSearchParams(newUrlQueryParams, { replace: true })
  }, [indexUiState]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (indexUiState && hitsStatus !== 'loading' && hitsStatus !== 'stalled') {
      setIsLoading(false)
    }
  }, [indexUiState, hitsStatus])

  return (
    <Container>
      {isLoading ? (
        <Skeleton active title={false} paragraph={{ rows: 24 }} />
      ) : (
        <>
          <h5 className="results-info">
            {isCatalogLoading
              ? 'Loading projects...'
              : `Showing ${results?.nbHits} project${results?.nbHits !== 1 && 's'}`}
          </h5>

          {indexUiState && (
            <div className="filter-category">
              <ModuleUserStatusFilter indexUiState={indexUiState} refine={refine} setPage={setPage} />
            </div>
          )}

          <div className="filter-category">
            <Collapse ghost defaultActiveKey={['1']}>
              <Collapse.Panel key="1" header={<h5 className="filter-title">Learning Area</h5>}>
                <RefinementList attribute="learning_area.name" showMore />
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="filter-category">
            <Collapse ghost defaultActiveKey={['1']}>
              <Collapse.Panel key="1" header={<h5 className="filter-title">Skill Track</h5>}>
                <RefinementList attribute="skill_track.name" showMore />
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="filter-category">
            <Collapse ghost defaultActiveKey={['1']}>
              <Collapse.Panel key="1" header={<h5 className="filter-title">Skill</h5>}>
                <RefinementList attribute="skill.name" showMore />
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="filter-category">
            <Collapse ghost defaultActiveKey={['1']}>
              <Collapse.Panel key="1" header={<h5 className="filter-title">Status</h5>}>
                <RefinementList attribute="status" showMore />
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="filter-category">
            <Collapse ghost defaultActiveKey={['1']}>
              <Collapse.Panel key="1" header={<h5 className="filter-title">Difficulty</h5>}>
                <RefinementList attribute="difficulty" showMore />
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="filter-category">
            <Collapse ghost defaultActiveKey={['1']}>
              <Collapse.Panel key="1" header={<h5 className="filter-title">Project type</h5>}>
                <RefinementList attribute="module_type" showMore />
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="filter-category">
            <Collapse ghost defaultActiveKey={['1']}>
              <Collapse.Panel key="1" header={<h5 className="filter-title">Tags</h5>}>
                <RefinementList attribute="tags.name" showMore />
              </Collapse.Panel>
            </Collapse>
          </div>
        </>
      )}
    </Container>
  )
}

export default LabFilters
