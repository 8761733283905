import { useSelector, useDispatch } from 'react-redux'
import { Tooltip, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import Button from '@/components/Button'
import { updatePlayground, createPlaygroundImage } from '@/store/playgrounds/actions'
import { showToast } from '@/utils/toast/index'
import { Container } from './styles'

const UploadPlaygroundImageButton = () => {
  const dispatch = useDispatch()

  const { wizard, isLoading } = useSelector((state) => state.playgrounds)

  const hasImage = !!wizard?.imageUrl

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      showToast('You can only upload JPG/PNG file!', 'error')
    }

    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      showToast('Image must smaller than 5MB!', 'error')
    }

    return isJpgOrPng && isLt5M
  }

  const title = isLoading ? 'Uploading image...' : 'Upload custom image'

  const onSuccess = wizard?.playgroundId ? (data) => dispatch(updatePlayground(wizard?.playgroundId, data)) : null

  return (
    <Container className="upload-playground-image-button" hasImage={hasImage}>
      <ImgCrop
        fillColor={'#f0f5ff'}
        aspect={'1.7'}
        modalTitle={title}
        modalWidth={500}
        onModalOk={(file) => dispatch(createPlaygroundImage(file, onSuccess))}
      >
        <Upload
          className="image-uploader"
          maxCount={1}
          showUploadList={{ showPreviewIcon: false }}
          beforeUpload={beforeUpload}
          customRequest={() => {}}
        >
          <Tooltip title={title}>
            <Button
              className="icon-button"
              type="text"
              icon={<UploadOutlined />}
              loading={isLoading}
              disabled={isLoading}
            >
              <span>
                Custom <br /> image
              </span>
            </Button>
          </Tooltip>
        </Upload>
      </ImgCrop>
    </Container>
  )
}

export default UploadPlaygroundImageButton
