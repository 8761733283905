import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Input } from 'antd'
import { MailTwoTone } from '@ant-design/icons'
import Button from '@/components/Button'
import { forgotPassword } from '@/store/users/actions'
import { LogoHeader, Form } from '../styles'

const ForgotPasswordForm = ({ toggleMode }) => {
  const dispatch = useDispatch()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const { isAuthModalOpen } = useSelector((state) => state.users)

  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const onSubmit = async (data) => {
    if (isSent) return

    setIsLoading(true)
    await dispatch(forgotPassword(data))
    setIsLoading(false)
    setIsSent(true)
  }

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit(onSubmit)} autoComplete="off">
      <LogoHeader>
        <h3>Reset your password</h3>
      </LogoHeader>

      {!isSent && (
        <>
          <p>Enter the email associated with your account and we will send you a password reset link.</p>

          <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Input {...field} type="email" size="large" placeholder="Your email address" prefix={<MailTwoTone />} />
            )}
          />
          {errors.email && <p className="error-text">{errors.email.message}</p>}

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Send password reset email
          </Button>
        </>
      )}

      {isSent && (
        <>
          <p>
            Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your
            spam folder.
          </p>

          {isAuthModalOpen ? (
            <a className="link" onClick={() => toggleMode('login')}>
              <Button type="primary">Return to sign in</Button>
            </a>
          ) : (
            <Link className="link" to={'/login'}>
              <Button type="primary">Return to sign in</Button>
            </Link>
          )}
        </>
      )}

      {!isSent &&
        (isAuthModalOpen ? (
          <p className="bottom-text">
            {"You don't have an account?"}{' '}
            <a className="link" onClick={() => toggleMode('signup')}>
              Register here
            </a>
          </p>
        ) : (
          <p className="bottom-text">
            You don't have an account?{' '}
            <Link className="link" to={`/register${window?.location?.search}`}>
              Register here
            </Link>
          </p>
        ))}
    </Form>
  )
}

export default ForgotPasswordForm
