import { createSlice } from '@reduxjs/toolkit'
import { currentEnv } from '@/helpers/env'
import { getDefaultUiConfig } from '@/helpers/getDefaultUiConfig'

const slice = createSlice({
  name: 'app',
  initialState: {
    uiConfig: getDefaultUiConfig(),
    environment: currentEnv,
    appError: null,
    appNotification: null,
    theme: localStorage.getItem('dw-theme') || 'light',
    pppInfo: null,
    isUpgradeToProModalOpen: false,
    isUpgradeToProSuccessModalOpen: false,
    pageSize: 20,
  },
  reducers: {
    APP_ERROR_UPDATED: (state, { payload }) => {
      state.appError = payload
    },
    APP_UI_CONFIG_UPDATED: (state, { payload }) => {
      state.uiConfig = payload
    },
    APP_NOTIFICATION_UPDATED: (state, { payload }) => {
      state.appNotification = payload
    },
    APP_THEME_UPDATED: (state, { payload }) => {
      state.theme = payload
    },
    PPP_INFO_UPDATED: (state, { payload }) => {
      state.pppInfo = payload
    },
    TOGGLE_UPGRADE_TO_PRO_MODAL: (state, { payload }) => {
      state.isUpgradeToProModalOpen = payload
    },
    TOGGLE_UPGRADE_TO_PRO_SUCCESS_MODAL: (state, { payload }) => {
      state.isUpgradeToProSuccessModalOpen = payload
    },
  },
})

export const {
  APP_ERROR_UPDATED,
  APP_UI_CONFIG_UPDATED,
  APP_NOTIFICATION_UPDATED,
  APP_THEME_UPDATED,
  PPP_INFO_UPDATED,
  TOGGLE_UPGRADE_TO_PRO_MODAL,
  TOGGLE_UPGRADE_TO_PRO_SUCCESS_MODAL,
} = slice.actions
export default slice.reducer
