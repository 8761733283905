import { createSlice } from '@reduxjs/toolkit'

const initialWizardState = {
  playgroundId: null,
  name: '',
  description: '',
  preJumpbox: null,
  jumpbox: null,
  preDataSource: null,
  dataSource: null,
  boost: false,
  type: '1',
  isEdit: false,
  isWizardModalOpen: false,
  error: null,
}

const initialState = {
  wizard: initialWizardState,
  myPlaygrounds: null,
  publishedPlaygrounds: null,
  archivedPlaygrounds: null,
  featuredPlaygrounds: null,
  currentPlayground: null,
  allocatedPlaygrounds: null,
  playgroundMode: null,
  playgroundPreview: null,
  isPlaygroundPreviewLoading: null,
  isPlaygroundPublishLoading: null,
  isPlaygroundVoteLoading: null,
  infoSideDrawer: null,
  isMultiplePlaygroundsModalOpen: false,
  isPublishModalOpen: false,
  isSharingModalOpen: false,
  isResetModalOpen: null,
  isFeedbackModalOpen: false,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'playgrounds',
  initialState,
  reducers: {
    SET_WIZARD_UPDATED: (state, { payload }) => {
      state.wizard = payload
    },
    TOGGLE_WIZARD_MODAL: (state, { payload }) => {
      state.wizard.isWizardModalOpen = payload
    },
    RESET_WIZARD_STATE: (state) => {
      state.wizard = initialWizardState
    },
    SET_PLAYGROUNDS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    MY_PLAYGROUNDS_UPDATED: (state, { payload }) => {
      state.myPlaygrounds = payload
      state.error = null
    },
    PUBLISHED_PLAYGROUNDS_UPDATED: (state, { payload }) => {
      state.publishedPlaygrounds = payload
      state.error = null
    },
    ARCHIVED_PLAYGROUNDS_UPDATED: (state, { payload }) => {
      state.archivedPlaygrounds = payload
      state.error = null
    },
    FEATURED_PLAYGROUNDS_UPDATED: (state, { payload }) => {
      state.featuredPlaygrounds = payload
      state.error = null
    },
    PLAYGROUND_UPDATED: (state, { payload }) => {
      state.currentPlayground = payload
      state.error = null
    },
    ALLOCATED_PLAYGROUNDS_UPDATED: (state, { payload }) => {
      state.allocatedPlaygrounds = payload
    },
    PLAYGROUND_MODE_UPDATED: (state, { payload }) => {
      state.playgroundMode = payload
    },
    PLAYGROUND_PREVIEW_UPDATED: (state, { payload }) => {
      state.playgroundPreview = payload
    },
    SET_PLAYGROUND_PREVIEW_LOADING: (state, { payload }) => {
      state.isPlaygroundPreviewLoading = payload
    },
    SET_PLAYGROUND_PUBLISH_LOADING: (state, { payload }) => {
      state.isPlaygroundPublishLoading = payload
    },
    SET_PLAYGROUND_VOTE_LOADING: (state, { payload }) => {
      state.isPlaygroundVoteLoading = payload
    },
    INFO_SIDE_DRAWER_UPDATED: (state, { payload }) => {
      state.infoSideDrawer = payload
    },
    TOGGLE_MULTIPLE_PLAYGROUNDS_MODAL: (state, { payload }) => {
      state.isMultiplePlaygroundsModalOpen = payload
    },
    TOGGLE_PUBLISH_MODAL: (state, { payload }) => {
      state.isPublishModalOpen = payload
    },
    SET_SHARING_MODAL_OPEN: (state, { payload }) => {
      state.isSharingModalOpen = payload
    },
    SET_RESET_MODAL_OPEN: (state, { payload }) => {
      state.isResetModalOpen = payload
    },
    TOGGLE_FEEDBACK_MODAL: (state, { payload }) => {
      state.isFeedbackModalOpen = payload
    },
    RESET_PLAYGROUNDS_STATE: () => initialState,
    SET_PLAYGROUNDS_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
  },
})

export const {
  SET_WIZARD_UPDATED,
  TOGGLE_WIZARD_MODAL,
  RESET_WIZARD_STATE,
  SET_PLAYGROUNDS_LOADING,
  MY_PLAYGROUNDS_UPDATED,
  PUBLISHED_PLAYGROUNDS_UPDATED,
  ARCHIVED_PLAYGROUNDS_UPDATED,
  FEATURED_PLAYGROUNDS_UPDATED,
  PLAYGROUND_UPDATED,
  ALLOCATED_PLAYGROUNDS_UPDATED,
  PLAYGROUND_MODE_UPDATED,
  PLAYGROUND_PREVIEW_UPDATED,
  SET_PLAYGROUND_PREVIEW_LOADING,
  SET_PLAYGROUND_PUBLISH_LOADING,
  SET_PLAYGROUND_VOTE_LOADING,
  INFO_SIDE_DRAWER_UPDATED,
  TOGGLE_MULTIPLE_PLAYGROUNDS_MODAL,
  TOGGLE_PUBLISH_MODAL,
  SET_SHARING_MODAL_OPEN,
  SET_RESET_MODAL_OPEN,
  TOGGLE_FEEDBACK_MODAL,
  RESET_PLAYGROUNDS_STATE,
  SET_PLAYGROUNDS_ERROR,
} = slice.actions
export default slice.reducer
