import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import useQueryParam from '@/hooks/useQueryParam'
import usePageTracking from '@/hooks/usePageTracking'
import { toggleUpgradeToProSuccessModal } from '@/store/app/actions'

const RouteTracking = ({ children, ...props }) => {
  const dispatch = useDispatch()

  const [proCheckoutSuccessful] = useQueryParam('pro_successful')

  const { userProfile } = useSelector((state) => state.users)
  const isPro = userProfile?.groups?.includes('Pro')

  usePageTracking()

  useEffect(() => {
    if (isPro && proCheckoutSuccessful === 'true') {
      dispatch(toggleUpgradeToProSuccessModal(true))
    }
  }, [proCheckoutSuccessful]) // eslint-disable-line react-hooks/exhaustive-deps

  return children || <Outlet />
}

export default RouteTracking
