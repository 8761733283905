import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished'
import { device } from '@/themes/breakpoints'

const coloredBadge = (color) =>
  color &&
  css`
    #medal-border {
      fill: ${() => lighten(0.05, color)} !important;
    }

    #internal-jewel {
      fill: ${() => darken(0.05, color)} !important;
      opacity: 1 !important;
    }
  `

export const BadgeContainer = styled.div`
  width: fit-content;
  display: inline-block;
  position: relative;

  /* .badge-progress {
    width: 200px;
    height: 200px;
    background: #ff000055;
    position: absolute;
    top: 0px;
  } */

  > svg {
    width: 126px;
    transition: 0.3s all;
    margin: -20px -16px;

    #medal-border {
      fill: ${(props) => props.theme.colors.base.gray6} !important;
    }

    #transparent-center {
      mix-blend-mode: hard-light !important;
    }

    #internal-jewel {
      fill: ${(props) => props.theme.colors.base.gray7} !important;
    }

    #ribbon {
      fill: ${(props) => props.theme.colors.base.gray1} !important;
    }

    ${(props) => css`
      ${props.earned
        ? coloredBadge(props.color)
        : css`
            opacity: 0.6;

            #ribbon {
              opacity: 0.4;
            }

            #spark {
              display: none;
            }
          `}
    `}
  }

  .glow {
    width: 100%;
    height: 90%;
    background: ${(props) => props.theme.colors.base.gray1};
    position: absolute;
    top: 0%;
    opacity: 0.2;
    filter: blur(20px);
    border-radius: 50%;
    pointer-events: none;
  }

  @media ${device.mdDown} {
    > svg {
      width: 96px;
    }
  }
`
