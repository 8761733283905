import { useSelector, useDispatch } from 'react-redux'
import Button from '@/components/Button'
import AllocatedModules from '@/pages/ModulePage/components/AllocatedModules'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { toggleMultiplePlaygroundsModal } from '@/store/playgrounds/actions'
import ProSuccessBg from '@/assets/images/plans/pro-success.png'
import { Modal } from './styles'
import { useEffect } from 'react'

const MultiplePlaygroundsModal = () => {
  const dispatch = useDispatch()

  const { allocatedPlaygrounds, isMultiplePlaygroundsModalOpen } = useSelector((state) => state.playgrounds)

  const handleUpgradeToPro = () => {
    dispatch(toggleUpgradeToProModal(true))
    dispatch(toggleMultiplePlaygroundsModal(false))
  }

  useEffect(() => {
    if (allocatedPlaygrounds?.length === 0) {
      dispatch(toggleMultiplePlaygroundsModal(false))
    }
  }, [allocatedPlaygrounds])

  return (
    <Modal
      open={isMultiplePlaygroundsModalOpen}
      onCancel={() => dispatch(toggleMultiplePlaygroundsModal(false))}
      keyboard={false}
      footer={null}
      className="upgrade-to-pro-success-modal"
    >
      <div className="content">
        <div className="header-container">
          <img className="image" src={ProSuccessBg} alt="Your are now PRO user" />

          <div className="title-container">
            <h2 className="title">
              Become <span className="pro">PRO</span>
            </h2>

            <p className="info-text">to edit multiple playgrounds simultaneously.</p>
            <p className="info-text">As a Basic User, you can only edit on playground at the same time.</p>
          </div>
        </div>

        {!!allocatedPlaygrounds?.length && (
          <div className="running-playgrounds">
            <p className="info-text">Other Playgrounds running:</p>

            <AllocatedModules
              allocatedModules={allocatedPlaygrounds}
              onClick={() => dispatch(toggleMultiplePlaygroundsModal(false))}
            />
          </div>
        )}

        <div className="footer">
          <Button type="primary" size="large" ispro="true" onClick={handleUpgradeToPro}>
            Upgrade to PRO
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default MultiplePlaygroundsModal
