import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 540px;
  height: 200px;

  ${(props) =>
    props.isDraft &&
    css`
      cursor: not-allowed;

      > a {
        pointer-events: none;
      }
    `}

  .skill-track-body {
    min-height: 155px;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      90.13deg,
      ${(props) => props.theme.colors.base.gray13} 23.54%,
      rgba(50, 56, 84, 0.93) 105.9%
    );
    position: relative;
    display: flex;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.colors.geekblue2};
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
    transition: 0.3s all;
    cursor: default;

    ${(props) =>
      !props.disableClick &&
      css`
        cursor: pointer;

        &:hover {
          background: linear-gradient(
            90.13deg,
            ${(props) => props.theme.colors.base.gray10}ee 23.54%,
            rgba(50, 56, 84, 0.85) 105.9%
          );
          border: 1px solid ${(props) => props.theme.colors.geekblue4};

          .la-image {
            .shape-1 {
              opacity: 0.6;
            }

            .shape-2 {
              opacity: 0.9;
            }
          }
        }
      `}

    .skill-track-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;

      .badge-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .skill-track-info {
        width: 100%;
        max-width: 310px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;

        .pre-title-content {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 4px;
        }

        .pre-title {
          color: ${(props) => props.theme.colors.base.geekblue3};
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 0.19em;
          line-height: 20px;
          text-transform: uppercase;
          margin-bottom: 0px;

          &.earned {
            width: fit-content;
            color: ${(props) => props.theme.colors.base.gray10};
            background-color: ${(props) => props.theme.colors.base.cyan5};
            border-radius: 20px;
            padding: 0px 10px;
          }

          &.coming-soon {
            width: fit-content;
            color: ${(props) => props.theme.colors.gray10};
            background-color: ${(props) => props.theme.colors.base.gray3};
            border-radius: 20px;
            text-transform: initial;
            letter-spacing: initial;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            padding: 0px 12px;
            margin-top: 6px;
          }
        }

        .rating {
          color: ${(props) => props.theme.colors.base.gray1};
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          margin-top: -2px;

          .ant-rate-star {
            margin-inline-end: 2px;

            svg {
              font-size: 14px;
            }

            &:not(.ant-rate-star-full) {
              .ant-rate-star-second {
                color: rgba(255, 255, 255, 0.5);
              }
            }
          }
        }

        .title {
          color: ${(props) => props.theme.colors.base.gray1};
          max-height: 56px;
          margin-bottom: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 4px;
        }

        .progress-container {
          max-width: 260px;
          display: flex;
          gap: 8px;

          .progress-text {
            color: ${(props) => props.theme.colors.base.gray1};
            font-size: 12px;
            margin: 0px;
          }

          .ant-progress {
            .ant-progress-inner {
              background-color: ${(props) => props.theme.colors.base.geekblue2};
            }

            .ant-progress-bg {
              background-color: ${(props) => props.theme.colors.base.geekblue5};
            }

            &.ant-progress-status-success .ant-progress-bg {
              background-color: ${(props) => props.theme.colors.base.cyan5};
            }
          }
        }

        .skill-bullets-box {
          display: flex;
          gap: 20px;
          margin-top: 6px;

          .skill-bullets-container {
            display: flex;
            gap: 12px;
            margin-bottom: 4px;
          }
        }

        .subtitle {
          color: ${(props) => props.theme.colors.base.gray7};
          font-size: 12px;
          line-height: 20px;
          margin: 0px;
        }
      }
    }

    .la-image {
      min-height: 100%;
      height: 100%;
      display: flex;
      position: absolute;
      top: 0px;
      right: -84px;

      .shape-1 {
        width: 42px;
        height: 100%;
        background: linear-gradient(
          70deg,
          ${(props) => props.theme.colors.base.gray10} 22%,
          rgba(21, 74, 178, 0.93) 137%
        );
        transform: skew(-26deg);
      }

      .shape-2 {
        width: 110px;
        height: 100%;
        background-color: ${(props) => props.color};
        filter: brightness(${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)});
        transform: skew(-26deg);
      }

      ${(props) =>
        props.isDraft &&
        css`
          opacity: 0.3;

          .shape-1 {
            background: ${(props) => props.theme.colors.base.gray10};
          }

          .shape-2 {
            background-color: ${(props) => props.theme.colors.base.gray8};
          }
        `}
    }
  }

  @media ${device.mdDown} {
    height: 270px;

    .skill-track-body {
      padding: 20px 30px;

      .skill-track-content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .badge-container {
          margin: 8px 0px 0px -10px;
        }
      }
    }
  }

  @media ${device.smDown} {
    width: 100%;
  }
`
