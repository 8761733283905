import { useState } from 'react'
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch'
import { useInstantSearch, useConfigure } from 'react-instantsearch'
import { SearchOutlined } from '@ant-design/icons'
import Layout1 from '@/layouts/Layout1'
import LabFilters from './components/LabFilters'
import Results from './components/Results'
import searchParameters from './searchParameters'
import { Container } from './styles'
import { algoliaSearchClient } from '@/utils/algolia'

const CatalogContent = () => {
  const { results, status, indexUiState, setIndexUiState } = useInstantSearch()

  const currentSearchParameters = indexUiState?.configure
  const { refine } = useConfigure({ ...currentSearchParameters })

  const [page, setPage] = useState(1)

  return (
    <>
      <div className="catalog-header">
        <div className="content">
          <h2 className="title">Catalog</h2>
          <div className="search-box">
            <SearchOutlined />
            <SearchBox placeholder="Search projects here..." />
          </div>
        </div>
      </div>

      <div className="catalog-body">
        <div className="content">
          <LabFilters
            results={results}
            status={status}
            indexUiState={indexUiState}
            setIndexUiState={setIndexUiState}
            refine={refine}
            setPage={setPage}
          />

          <Results
            results={results}
            status={status}
            indexUiState={indexUiState}
            refine={refine}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
    </>
  )
}

const CatalogPage = () => {
  return (
    <Layout1 navbar>
      <Container>
        <InstantSearch searchClient={algoliaSearchClient} indexName={import.meta.env.REACT_APP_ALGOLIA_LABS_INDEX}>
          <Configure {...searchParameters} />

          <CatalogContent />
        </InstantSearch>
      </Container>
    </Layout1>
  )
}

export default CatalogPage
