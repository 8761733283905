import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Skeleton, Tooltip } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  ArrowSquareDown24Regular,
  ShareAndroid24Regular,
  TextDescription24Regular,
  ArrowRotateCounterclockwise24Regular,
} from '@fluentui/react-icons'
import { isDown } from '@/themes/breakpoints'
import Button from '@/components/Button'
import PlaygroundVote from '@/components/PlaygroundVote'
import PlaygroundStatusTag from '../PlaygroundStatusTag'
import {
  updatePlaygroundMode,
  createPlaygroundPreview,
  togglePublishModal,
  setSharingModalOpen,
  selectInfoSideDrawerItem,
} from '@/store/playgrounds/actions'
import { Container } from './styles'

const PlaygroundHeader = ({ tryAllocateLab }) => {
  const dispatch = useDispatch()

  const {
    currentPlayground: playground,
    playgroundMode,
    infoSideDrawer,
    isPlaygroundPreviewLoading,
    isLoading: isPlaygroundLoading,
  } = useSelector((state) => state.playgrounds)
  const { currentLab: lab, currentViewIsLoaded, isLabLoading } = useSelector((state) => state.labs)

  const isCustomProject = !!playground?.metadata?.account_id
  const isMemberCopy = playground?.metadata?.is_student_copy
  const labSession = lab?.allocated_session
  const isDeviceStopping = labSession?.devices?.some((d) => d.status === 'stopping')
  const isLabSessionAllocated = labSession?.status === 'allocated'

  const hasPreview = !!playground?.preview_html
  const hasVersions = !!playground?.versions?.length
  const lastVersion = playground?.versions?.[playground?.versions?.length - 1]
  const isPublished = playground?.status === 'published'

  const handlePublish = () => {
    dispatch(togglePublishModal(true))

    if (isCustomProject && isLabSessionAllocated) {
      dispatch(createPlaygroundPreview(playground?.id))
    }
  }

  const handleSharing = () => {
    dispatch(setSharingModalOpen(true))
  }

  const handleOpenSideDrawer = (key) => {
    dispatch(selectInfoSideDrawerItem(key))
  }

  const goToPreviewMode = () => {
    dispatch(updatePlaygroundMode('preview'))
    dispatch(createPlaygroundPreview(playground?.id))
  }

  const renderInfoContent = () => {
    if (isPlaygroundLoading || isLabLoading || !playground || !lab) {
      return (
        <div className="info">
          <div className="loading-content">
            <Skeleton className="title-loading" active title={false} paragraph={{ rows: 2 }} round />
            <Skeleton className="mode-loading" active title={false} paragraph={{ rows: 1 }} />
          </div>

          <div className="actions">
            <div className="left">
              <Skeleton className="action-loading" active title={false} paragraph={{ rows: 1 }} />
              <Skeleton className="action-loading" active title={false} paragraph={{ rows: 1 }} />
            </div>

            <Skeleton className="left action-loading" active title={false} paragraph={{ rows: 1 }} />
          </div>
        </div>
      )
    }

    return (
      <div className="info">
        <div className="header">
          <div className="title-container">
            {!isCustomProject && (
              <Tooltip title="Back to Playgrounds" placement="bottom">
                <Link className="back-btn" to={'/playgrounds'}>
                  <Button className="back-btn" type="text">
                    <ArrowLeftOutlined className="icon" />
                  </Button>
                </Link>
              </Tooltip>
            )}

            <h4 className="title">{playground?.name}</h4>
          </div>

          <div className="subtitle-container" style={{ paddingLeft: isCustomProject ? '0px' : '30px' }}>
            {playground?.status === 'draft' && (
              <>
                <PlaygroundStatusTag status={playground?.status} isCustomProject={isCustomProject} />
                <span className="subtitle">Started {dayjs(playground?.created).from()}</span>
              </>
            )}

            {playground?.status === 'published' && (
              <>
                <PlaygroundStatusTag
                  status={playground?.status}
                  visibility={playground?.visibility}
                  isCustomProject={isCustomProject}
                />
                <span className="subtitle">
                  {isMemberCopy ? 'Submitted' : 'Published'} {dayjs(lastVersion?.created).from()}
                </span>
              </>
            )}
          </div>
        </div>

        <div className="actions">
          {playgroundMode === 'preview' && (
            <>
              <div className="left">
                <Tooltip title={(infoSideDrawer || isDown('md')) && 'Description'} placement="bottom">
                  <Button onClick={() => handleOpenSideDrawer('description')}>
                    <TextDescription24Regular className="description-icon" />
                  </Button>
                </Tooltip>

                <Tooltip
                  title={(infoSideDrawer || isDown('md')) && (isMemberCopy ? 'Submissions' : 'Version history')}
                  placement="bottom"
                >
                  <Button onClick={() => handleOpenSideDrawer('version-history')}>
                    <ArrowRotateCounterclockwise24Regular className="version-history-icon" />
                  </Button>
                </Tooltip>
              </div>

              <div className="right">
                {hasPreview && (
                  <Tooltip
                    title={(infoSideDrawer || isDown('md')) && (isMemberCopy ? 'Create submission' : 'Publish')}
                    placement="bottom"
                  >
                    <Button type="secondary" onClick={handlePublish}>
                      <ArrowSquareDown24Regular className="publish-icon" />
                      {!infoSideDrawer && (
                        <span className="text">{isMemberCopy ? 'Create submission' : 'Publish'}</span>
                      )}
                    </Button>
                  </Tooltip>
                )}

                {!isMemberCopy && hasVersions && isPublished && (
                  <Tooltip title={(infoSideDrawer || isDown('md')) && 'Share'} placement="bottom">
                    <Button onClick={handleSharing}>
                      <ShareAndroid24Regular />
                      {!infoSideDrawer && <span className="text">Share</span>}
                    </Button>
                  </Tooltip>
                )}

                {!isCustomProject && <PlaygroundVote playground={playground} shape="squared" isReadOnly />}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  const renderSecondaryContent = () => {
    if (isPlaygroundLoading || isLabLoading || !playground || !lab) return

    if (playgroundMode === 'preview')
      return (
        <div className="secondary-content">
          <div className="mode preview">
            {!infoSideDrawer && <h5 className="text">You're in preview mode</h5>}
            <Button
              id="playground-edit-mode-btn"
              type="primary"
              onClick={tryAllocateLab}
              loading={isPlaygroundPreviewLoading || isDeviceStopping}
            >
              {isDeviceStopping
                ? isCustomProject
                  ? 'Saving project'
                  : 'Saving playground'
                : isLabSessionAllocated
                  ? 'Continue editing'
                  : 'Start editing'}
            </Button>
          </div>
        </div>
      )

    if (playgroundMode === 'edit' && labSession)
      return (
        <div className="secondary-content">
          {isCustomProject ? (
            <div className="mode edit">
              {!infoSideDrawer && <h5 className="text">You're in edit mode</h5>}
              <Button
                id="playground-preview-mode-btn"
                type="primary"
                onClick={handlePublish}
                loading={!currentViewIsLoaded}
              >
                Preview and {isMemberCopy ? 'Submit' : 'Publish'}
              </Button>
            </div>
          ) : (
            <div className="mode edit">
              {!infoSideDrawer && <h5 className="text">You're in edit mode</h5>}
              <Button
                id="playground-preview-mode-btn"
                type="primary"
                onClick={goToPreviewMode}
                loading={!currentViewIsLoaded}
              >
                Preview playground
              </Button>
            </div>
          )}
        </div>
      )
  }

  return (
    <Container className="playground-header">
      {renderInfoContent()}

      {renderSecondaryContent()}
    </Container>
  )
}

export default PlaygroundHeader
