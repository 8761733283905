import { useSelector, useDispatch } from 'react-redux'
import Button from '@/components/Button'
import DeviceSelector from '@/pages/ModulePage/components/DeviceSelector'
import LabDeallocateButton from '@/pages/ModulePage/components/LabDeallocateButton'
import DataSourceDetailContent from '@/pages/PlaygroundsPage/components/WizardModal/components/DataSourceDetailContent'
import PlaygroundForkInfo from '@/components/PlaygroundForkInfo'
import { updatePlaygroundMode, setResetModalOpen } from '@/store/playgrounds/actions'
import { Container } from './styles'

const DescriptionDrawerItem = () => {
  const dispatch = useDispatch()

  const { currentPlayground: playground, isLoading: isPlaygroundsLoading } = useSelector((state) => state.playgrounds)
  const { isLoading: isDataSourceLoading } = useSelector((state) => state.dataSources)
  const { currentLab: lab } = useSelector((state) => state.labs)
  const labSession = lab?.allocated_session

  const isCustomProject = !!playground?.metadata?.account_id
  const isMemberCopy = playground?.metadata?.is_student_copy
  const sourceType = playground?.data_sources?.[0]?.source_type
  const isFilesystem = sourceType === 'filesystem'

  const handleRestartCustomProject = () => {
    dispatch(setResetModalOpen(true))
  }

  return (
    <Container className="description-drawer-item">
      <div className="header">
        <h5 className="title">Description</h5>
      </div>

      <div className="content">
        <div className="data-source-info">
          <h5 className="title">{isFilesystem ? 'How to find attached data' : 'How to find your device'}</h5>

          {isFilesystem && (
            <p className="description">
              Datasets are available at <br />
              <code>{playground?.data_sources?.[0]?.filesystem_path}</code>
            </p>
          )}

          {(!lab?.allocated_session || lab?.allocated_session?.status === 'deallocated') && (
            <p className="description">
              Start editing your {isCustomProject ? 'project' : 'playground'} to view devices info here.
            </p>
          )}

          {lab?.allocated_session?.devices && (
            <>
              <p className="description">Available devices:</p>

              <DeviceSelector collapsible={false} />
            </>
          )}

          <div className="actions">
            {isMemberCopy && (
              <Button type="primary" danger onClick={handleRestartCustomProject} loading={isPlaygroundsLoading}>
                Restart project
              </Button>
            )}

            {lab?.allocated_session?.devices && (
              <LabDeallocateButton
                labId={playground?.lab_id}
                labSessionId={labSession?.id}
                text={`Stop ${isCustomProject ? 'project' : 'playground'}`}
                type="text"
                callback={() => dispatch(updatePlaygroundMode('preview'))}
              />
            )}
          </div>
        </div>

        {!isCustomProject && <PlaygroundForkInfo playground={playground} />}

        <DataSourceDetailContent
          dataSource={playground?.data_sources?.[0]}
          isLoading={isDataSourceLoading || isPlaygroundsLoading}
        />
      </div>
    </Container>
  )
}

export default DescriptionDrawerItem
