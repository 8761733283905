import { Star24Regular } from '@fluentui/react-icons'
import { Switch } from 'antd'
import ProTooltip from '@/components/ProTooltip'
import { Content } from './styles'

const SettingBox = ({
  title,
  subtitle,
  icon,
  showProTooltip,
  proTitle,
  proContent,
  proContentIsLarge,
  value,
  onToggle,
  isLoading,
  isComingSoon,
}) => {
  return (
    <Content className="setting-box" disabled={showProTooltip}>
      <div className="icon-container">{icon || <Star24Regular />}</div>

      <div className="content">
        <p className="title">{title}</p>
        {subtitle && <p className="text">{subtitle}</p>}
      </div>

      <div className="items-container">
        {isComingSoon ? (
          <span className="coming-soon-tag">coming soon</span>
        ) : (
          <ProTooltip
            isLarge={proContentIsLarge}
            title={showProTooltip && proTitle}
            content={proContent}
            showUpgradeButton
          >
            <Switch checked={value} onChange={onToggle} disabled={showProTooltip} loading={isLoading} />
          </ProTooltip>
        )}
      </div>
    </Content>
  )
}

export default SettingBox
