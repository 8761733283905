import { Progress } from './styles'

const BottomProgress = ({ value }) => {
  return (
    <Progress value={value}>
      <span className="progress-value" />
    </Progress>
  )
}

export default BottomProgress
