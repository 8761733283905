import styled, { css } from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  width: 100% !important;
  display: flex;
  align-self: center;
  padding: 20px;

  ${(props) =>
    props.isCustomProject
      ? css`
          max-width: 1020px;

          .ant-modal-content {
            min-height: 740px;

            .ant-modal-body {
              > .content {
                padding: 40px;

                > .header-container {
                  border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
                }
              }
            }
          }
        `
      : css`
          max-width: 430px;

          .ant-modal-content {
            width: 390px;

            .ant-modal-body {
              > .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 60px 0px 40px;

                > .header-container {
                  max-width: 280px;
                }
              }
            }
          }
        `}

  .ant-modal-content {
    padding: 0px 20px;

    .ant-modal-body {
      overflow: hidden;

      > .content {
        > .header-container {
          padding: 0px 0px 14px;

          ${(props) =>
            props.isCustomProject
              ? css`
                  text-align: left;
                `
              : css`
                  text-align: center;
                `}

          .pre-title {
            color: ${(props) => props.theme.colors.gray7};
            display: flex;
            gap: 8px;
            align-items: center;

            .bold {
              color: ${(props) => props.theme.colors.gray10};
              font-weight: 500;
            }

            .icon {
              width: 24px;
              height: 24px;
              color: ${(props) => props.theme.colors.geekblue5};
            }
          }

          .playground-name {
            font-weight: 700;
          }

          .header-image {
            width: 140px;
            opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.8 : 1)};
            margin-bottom: 14px;
          }

          .actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 12px;

            .publish-icon {
              width: 18px;
              height: 18px;
              transform: rotate(180deg);
            }
          }

          .info-message {
            width: fit-content;
            background-color: ${(props) => props.theme.colors.gray2};
            color: ${(props) => props.theme.colors.gray7};
            border: 1px solid ${(props) => props.theme.colors.gray4};
            display: flex;
            align-items: center;
            gap: 6px;
            text-align: left;
            border-radius: 4px;
            padding: 4px 12px;

            .icon {
              min-width: 20px;
              max-width: 20px;
              min-height: 20px;
              max-height: 20px;
            }

            &.gold {
              background-color: ${(props) => props.theme.colors.gold1};
              color: ${(props) => props.theme.colors.gold6};
              border: 1px solid ${(props) => props.theme.colors.gold4};
            }
          }

          .title {
            font-weight: 400;
            margin: 0px 0px 4px;
          }

          .info-text {
            color: ${(props) =>
              localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray10 : props.theme.colors.gray8};
            margin: 0px;
          }
        }

        .version-info {
          width: 100%;
          border-top: 1px solid ${(props) => props.theme.colors.gray4};

          .version-item {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
            padding: 10px 0px;

            .version-code {
              min-width: 40px;
              min-height: 40px;
              background-color: ${(props) => props.theme.colors.green2};
              color: ${(props) => props.theme.colors.green6};
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 400;
              text-transform: uppercase;
              border-radius: 50%;
              margin: 0px;
            }

            .playground-name {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              margin: 0px;
            }
          }
        }

        .visibility-box {
          text-align: center;
          margin-top: 20px;

          .title {
            color: ${(props) => props.theme.colors.gray8};
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
          }
        }

        .preview-container {
          margin-top: 20px;
        }

        .footer {
          display: flex;
          gap: 20px;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }
`
