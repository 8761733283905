import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Checkbox, Alert } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import Button from '@/components/Button'
import { deallocateLab } from '@/store/labs/actions'
import { createPlayground, setResetModalOpen } from '@/store/playgrounds/actions'
import { Modal } from './styles'

// THIS IS ONLY AVAILABLE IF playground?.metadata?.is_student_copy === true
// TODO: Merge with resetModuleModal?
const ResetPlaygroundModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isChecked, setIsChecked] = useState(false)

  const {
    currentPlayground: playground,
    isResetModalOpen,
    isLoading: isPlaygroundsLoading,
  } = useSelector((state) => state.playgrounds)
  const { currentLab: lab, isLabLoading } = useSelector((state) => state.labs)
  const labSession = lab?.allocated_session

  const handleCancel = () => {
    dispatch(setResetModalOpen(false))
  }

  const handleReset = async () => {
    const auxCustomProject = {
      playground_id: playground?.copied_from?.playground_id,
      name: playground?.name,
      metadata: {
        account_id: playground?.metadata?.account_id,
        is_student_copy: true,
      },
      visibility: 'private',
    }

    if (labSession) {
      await dispatch(
        deallocateLab({ labId: playground?.lab_id, labSessionId: labSession?.id, deallocateReason: 'manual' }),
      )
    }

    await dispatch(
      createPlayground(auxCustomProject, (p) =>
        navigate(`/custom-projects/${playground?.metadata?.account_id}/${p?.id}?mode=edit`, { replace: true }),
      ),
    )

    handleCancel()
  }

  useEffect(() => {
    if (isResetModalOpen) return

    setIsChecked(false)
  }, [isResetModalOpen])

  return (
    <Modal
      open={isResetModalOpen}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={null}
      className="reset-module-modal"
    >
      <div className="content">
        <div className="header">
          <WarningFilled className="icon" />

          <h4 className="title">Are you sure you want to restart your project back to its default state?</h4>

          <Alert message="All the progress you’ve made and project submissions will be lost." type="warning" showIcon />

          <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
            Yes, I’m sure
          </Checkbox>
        </div>

        <div className="actions">
          <Button size="large" type="default" onClick={handleCancel} loading={isPlaygroundsLoading || isLabLoading}>
            Cancel
          </Button>

          <Button
            className="cta-button"
            size="large"
            type="primary"
            danger
            onClick={handleReset}
            disabled={!isChecked}
            loading={isPlaygroundsLoading || isLabLoading}
          >
            Restart project
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ResetPlaygroundModal
