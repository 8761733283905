import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.geekblue4};
  position: absolute;
  right: -30px;
  top: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  gap: 5px;
  transform: rotate(-90deg);
  cursor: pointer;
  transition: 0.3s all;

  > span {
    color: ${(props) => props.theme.colors.gray13};
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0.4px;
    padding: 4px 12px;
  }

  &:hover {
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  }
`
