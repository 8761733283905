import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  > .header {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
    padding: 16px 24px;

    .title {
      font-weight: 500;
      margin: 0px;
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: auto;
    padding: 24px;

    .data-source-info {
      background-color: ${(props) => props.theme.colors.geekblue1};
      padding: 24px;

      > .title {
        font-weight: 700;
        margin: 0px 0px 8px;
      }

      .description {
        color: ${(props) => props.theme.colors.gray8};
        margin: 10px 0px 0px;
      }
    }

    .playground-fork-info {
      margin-bottom: -20px;
    }

    .device-selector {
      border-top: 1px solid ${(props) => props.theme.colors.geekblue2};
      margin: 6px 0px 10px;

      .device-menu-item {
        border-color: ${(props) => props.theme.colors.geekblue2};
      }
    }

    .actions {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      gap: 14px;
      margin: 14px 0px 0px;

      #lab-stop-btn {
        border: 1px solid ${(props) => props.theme.colors.red4};
      }
    }
  }
`
