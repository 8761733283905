import styled from 'styled-components'

export const Container = styled.div`
  .nav {
    height: 80px;
    max-width: 860px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    z-index: 1;

    .header-container {
      width: 100%;
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .comments-button {
        .ant-scroll-number {
          background-color: ${(props) => props.theme.colors.blue6};
        }
      }

      .fade-in-mask {
        width: 100%;
        height: 100%;
      }

      .module-name-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        cursor: pointer;

        .module-name {
          color: ${(props) => props.theme.colors.text};
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 0px;

          svg {
            font-size: 16px;
            margin-left: 6px;
            transition: 0.1s all;
            ${(props) => props.isOpen && `transform: rotate(180deg);`}
          }
        }
      }
    }
  }
`

export const PagesContainer = styled.div`
  width: 100%;
  height: 0px;
  max-height: 500px;
  background-color: ${(props) => props.theme.colors.gray1};
  position: absolute;
  overflow: auto;
  padding: 0px 30px;
  transition: 0.1s all;

  ${(props) =>
    props.isOpen &&
    `
    height: fit-content;
    padding: 14px 30px 0px 30px;
    box-shadow: 0px 0px 100px 1400px rgba(0, 0, 0, 0.2);
    `}

  .page-list {
    max-width: 600px;
    margin: 0px auto 10px;

    .ant-steps-item {
      cursor: pointer;
      padding: 6px 0px 6px 6px;

      &:hover {
        background-color: ${(props) => props.theme.colors.geekblue1}77;
      }

      &.ant-steps-item-process {
        background-color: ${(props) => props.theme.colors.geekblue1};

        .ant-steps-icon-dot {
          background: ${(props) => props.theme.colors.gray10} !important;
        }
      }

      .ant-steps-item-container {
        .ant-steps-item-tail {
          display: none !important;

          &:after {
            background-color: ${(props) => props.theme.colors.gray4};
          }
        }

        .ant-steps-item-icon {
          display: none;
        }

        .ant-steps-item-content {
          min-height: 36px;
          display: flex;

          .ant-steps-item-title {
            width: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .actions {
    max-width: 600px;
    background: linear-gradient(transparent, ${(props) => props.theme.colors.gray1} 20%);
    display: flex;
    justify-content: center;
    gap: 8px;
    position: sticky;
    bottom: 0px;
    padding: 30px 0px 20px;
    margin: 0px auto;

    button {
      flex: 0 1 50%;

      svg {
        color: ${(props) => props.theme.colors.yellow6};
      }
    }
  }
`

export const PageItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0px;
  }
`
