import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  featuredModules: null,
  inProgressModules: null,
  finishedModules: null,
  relatedModulesInfo: null,
  newModules: null,
  multiModulesProgress: null,
  currentModule: null,
  modulePreview: null,
  currentPage: null,
  moduleConversation: null,
  // moduleReport: null,
  allocatedModules: null,
  toolbar: null,
  isModuleTimerModalAutoOpened: false,
  isModuleTimerModalOpen: false,
  isResetModuleModalOpen: null,
  isRatingModalOpen: false,
  isSharingModalOpen: false,
  isFinishedModuleModalOpen: false,
  isModuleLoading: false,
  isModuleAttemptLoading: false,
  isModulePreviewLoading: null,
  isModuleRatingLoading: false,
  isCatalogLoading: false,
  moduleAttemptError: null,
  error: null,
  modulePreviewError: null,
}

const slice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    SET_MODULE_LOADING: (state, { payload }) => {
      state.isModuleLoading = payload
    },
    SET_MODULE_ATTEMPT_LOADING: (state, { payload }) => {
      state.isModuleAttemptLoading = payload
    },
    SET_MODULE_PREVIEW_LOADING: (state, { payload }) => {
      state.isModulePreviewLoading = payload
    },
    SET_MODULE_RATING_LOADING: (state, { payload }) => {
      state.isModuleRatingLoading = payload
    },
    SET_CATALOG_LOADING: (state, { payload }) => {
      state.isCatalogLoading = payload
    },
    FEATURED_MODULES_UPDATED: (state, { payload }) => {
      state.featuredModules = payload
      state.error = null
      state.moduleAttemptError = null
    },
    IN_PROGRESS_MODULES_UPDATED: (state, { payload }) => {
      state.inProgressModules = payload
      state.error = null
    },
    FINISHED_MODULES_UPDATED: (state, { payload }) => {
      state.finishedModules = payload
      state.error = null
    },
    RELATED_MODULES_INFO_UPDATED: (state, { payload }) => {
      state.relatedModulesInfo = payload
    },
    NEW_MODULES_UPDATED: (state, { payload }) => {
      state.newModules = payload
    },
    MULTI_MODULES_PROGRESS_UPDATED: (state, { payload }) => {
      state.multiModulesProgress = payload
    },
    MODULE_UPDATED: (state, { payload }) => {
      state.currentModule = payload
      state.isModuleLoading = false
      state.error = null
    },
    MODULE_PREVIEW_UPDATED: (state, { payload }) => {
      state.modulePreview = payload
    },
    // MODULE_REPORT_UPDATED: (state, { payload }) => {
    //   state.moduleReport = payload
    //   state.error = null
    // },
    MODULE_CONVERSATION_UPDATED: (state, { payload }) => {
      state.moduleConversation = payload
    },
    ALLOCATED_MODULES_UPDATED: (state, { payload }) => {
      state.allocatedModules = payload
    },
    MODULE_TOOLBAR_UPDATED: (state, { payload }) => {
      state.toolbar = payload
    },
    PAGE_UPDATED: (state, { payload }) => {
      state.currentPage = payload
    },
    SET_MODULE_TIMER_MODAL_AUTO_OPENED: (state, { payload }) => {
      state.isModuleTimerModalAutoOpened = payload
    },
    SET_MODULE_TIMER_MODAL_OPEN: (state, { payload }) => {
      state.isModuleTimerModalOpen = payload
    },
    SET_RESET_MODULE_MODAL_OPEN: (state, { payload }) => {
      state.isResetModuleModalOpen = payload
    },
    SET_RATING_MODAL_OPEN: (state, { payload }) => {
      state.isRatingModalOpen = payload
    },
    SET_SHARING_MODAL_OPEN: (state, { payload }) => {
      state.isSharingModalOpen = payload
    },
    SET_FINISHED_MODULE_MODAL_OPEN: (state, { payload }) => {
      state.isFinishedModuleModalOpen = payload
    },
    RESET_MODULES_STATE: () => initialState,
    SET_MODULE_ERROR: (state, { payload }) => {
      state.error = payload
      state.isModuleLoading = false
    },
    SET_MODULE_ATTEMPT_ERROR: (state, { payload }) => {
      state.moduleAttemptError = payload
    },
    SET_MODULE_PREVIEW_ERROR: (state, { payload }) => {
      state.modulePreviewError = payload
    },
  },
})

export const {
  SET_MODULE_LOADING,
  SET_MODULE_ATTEMPT_LOADING,
  SET_MODULE_PREVIEW_LOADING,
  SET_MODULE_RATING_LOADING,
  SET_CATALOG_LOADING,
  MULTI_MODULES_UPDATED,
  FEATURED_MODULES_UPDATED,
  IN_PROGRESS_MODULES_UPDATED,
  FINISHED_MODULES_UPDATED,
  RELATED_MODULES_INFO_UPDATED,
  NEW_MODULES_UPDATED,
  MULTI_MODULES_PROGRESS_UPDATED,
  MODULE_UPDATED,
  MODULE_PREVIEW_UPDATED,
  // MODULE_REPORT_UPDATED,
  MODULE_CONVERSATION_UPDATED,
  ALLOCATED_MODULES_UPDATED,
  MODULE_TOOLBAR_UPDATED,
  PAGE_UPDATED,
  SET_MODULE_TIMER_MODAL_AUTO_OPENED,
  SET_MODULE_TIMER_MODAL_OPEN,
  SET_RESET_MODULE_MODAL_OPEN,
  SET_RATING_MODAL_OPEN,
  SET_SHARING_MODAL_OPEN,
  SET_FINISHED_MODULE_MODAL_OPEN,
  RESET_MODULES_STATE,
  SET_MODULE_ERROR,
  SET_MODULE_ATTEMPT_ERROR,
  SET_MODULE_PREVIEW_ERROR,
} = slice.actions
export default slice.reducer
