import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 5px;
  padding: 24px;
  margin-bottom: 20px;

  > .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 8px;

    .section-title {
      width: 210px;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin: 0px;

      .subtitle {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin: 0px;
      }
    }

    .view-all-button {
      white-space: nowrap;
      margin: 0px;
    }
  }

  > .datasource-cards-container {
    width: 100%;
    height: fit-content;

    .datasource-card-container {
      background-color: ${(props) => props.theme.colors.gray1};
      width: 342px;
      overflow: hidden;
    }

    .swiper-slide {
      padding: 8px 0px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      background: none;

      &:after {
        height: 32px;
        width: 32px;
        background-color: ${(props) => props.theme.colors.gray13};
        color: ${(props) => props.theme.colors.gray1};
        border-radius: 50%;
        font-size: 14px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2px 8px 0px ${(props) => props.theme.colors.gray13}26;
      }
    }
  }

  .loading-container {
    width: 100%;
    display: flex;
    gap: 16px;
    overflow: hidden;

    .ant-skeleton {
      width: initial;
      margin: 0px;

      .ant-skeleton-paragraph {
        margin: 0px;

        li {
          height: 146px !important;
          width: 328px !important;
          border-radius: 10px;
        }
      }
    }
  }

  @media ${device.mdDown} {
    .header {
      .view-all-button {
        display: none;
      }
    }
  }

  @media ${device.smDown} {
    .datasource-cards-container {
      .datasource-card-container {
        width: 250px;
      }
    }
  }
`
