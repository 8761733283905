import { useSelector } from 'react-redux'
import Button from '@/components/Button'
import { WarningFilled, CloseCircleFilled } from '@ant-design/icons'
import { Content } from './styles'

const LabError = ({ type, title, content, buttonContent, buttonOnClick }) => {
  const { uiConfig } = useSelector((state) => state.app)

  return (
    <Content>
      {type === 'warning' && <WarningFilled className="warning-icon" />}
      {type === 'danger' && <CloseCircleFilled className="danger-icon" />}

      {title && <h3 className="title">{title}</h3>}
      {content && <div className="content">{content}</div>}

      {uiConfig?.showErrorCardActions && (
        <div className="actions">
          <Button type="primary" onClick={buttonOnClick}>
            {buttonContent}
          </Button>
        </div>
      )}
    </Content>
  )
}

export default LabError
