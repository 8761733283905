'use client'

import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  min-height: 126px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  border: 1px solid ${(props) => props.theme.colors.gray4};
  border-radius: 10px;
  padding: 20px;
  transition: 0.3s all;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.geekblue3};
  }

  > .content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;

    .icon-container {
      width: 75px;
      min-width: 75px;
      height: 75px;
      min-height: 75px;
      background: linear-gradient(130deg, #3c276a 20%, ${(props) => props.theme.colors.base.geekblue5} 220%);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      border-radius: 10px;

      .theme-icon {
        width: 34px;
        height: 34px;
        color: ${(props) => props.theme.colors.base.gray1};
        font-size: 34px;
        flex: 1;
      }

      .tag {
        width: 100%;
        border-radius: 0px 0px 7px 7px;
        gap: 4px;
        padding: 2px 6px;
        margin: 0px;

        &.beta {
          background-color: ${(props) => props.theme.colors.magenta1};
          color: ${(props) => props.theme.colors.magenta5};
          border: 1px solid ${(props) => props.theme.colors.magenta5};
          text-align: center;
        }

        &.draft {
          background-color: ${(props) => props.theme.colors.volcano2};
          color: ${(props) => props.theme.colors.volcano6};
          border: 1px solid ${(props) => props.theme.colors.volcano5};
          text-align: center;
        }
      }
    }

    .header {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 6px;

      .title-container {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .title {
          display: flex;
          align-items: flex-start;
          gap: 6px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin: 0px;

          .type-icon {
            height: 22px;
            color: ${(props) => props.theme.colors.geekblue5};
            font-size: 16px;
          }
        }

        .description {
          * {
            color: ${(props) => props.theme.colors.gray9};
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            margin: 0px;
          }
        }

        .tags-container {
          max-height: 24px;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          gap: 6px;
          margin-top: 2px;

          .tag {
            margin: 0px;
          }
        }
      }

      .right-section {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        gap: 10px;

        .format-icon,
        .engine-icon {
          max-height: 26px;
          width: 42px;
          color: ${(props) => props.theme.colors.geekblue5};
          font-size: 16px;
        }
      }
    }
  }

  .pro-icon {
    width: 100px;
    height: 100px;
    background: linear-gradient(84deg, #222249 24.3%, #5479f7 67.59%, #89d2c2 99.33%);
    position: absolute;
    top: -64px;
    right: -50px;
    border-radius: 100%;

    .pro-badge {
      width: 18px;
      height: 15px;
      position: absolute;
      bottom: 12px;
      left: 22px;
    }
  }

  @media ${device.smDown} {
    min-height: 102px;
    justify-content: center;
    gap: 8px;
    padding: 14px;

    > .content {
      .icon-container {
        width: 54px;
        min-width: 54px;
        height: 54px;
        min-height: 54px;

        .theme-icon {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }
      }

      .header {
        .title-container {
          gap: 2px;

          .title {
            font-size: 14px;
            line-height: 18px;
          }

          .description {
            * {
              font-size: 13px;
              line-height: 18px;
              margin: 4px 0px 0px;
            }
          }
        }
      }

      .tags-container {
        gap: 4px;

        .tag {
          font-size: 10px;
          line-height: 14px;
          padding: 2px 6px;
        }
      }
    }
  }
`
