import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GlobalLoader from '@/components/GlobalLoader'
import UpgradeToProSuccessModal from '@/components/UpgradeToProSuccessModal'
import { setAppError, setAppNotification } from '@/store/app/actions'
import { tryAutoLogin, getUserStats } from '@/store/users/actions'
import Router from '@/router'
import Theme from '@/themes/base'
import { initSentry } from '@/utils/sentry'
import { initializeDayjs } from '@/utils/dayjs'

initSentry()
initializeDayjs()

const App = () => {
  const dispatch = useDispatch()
  const [isPageLoading, setPageLoading] = useState(true)

  const { appError, appNotification } = useSelector((state) => state.app)
  const { isAuthenticated } = useSelector((state) => state.users)

  const onLoad = useCallback(async () => {
    await dispatch(tryAutoLogin())
    setPageLoading(false)
  }, [])

  if (appError) {
    // toastError(appError)
    dispatch(setAppError(null))
  }

  if (appNotification) {
    // toastNotification(appNotification)
    dispatch(setAppNotification(null))
  }

  useEffect(() => {
    if (isAuthenticated === null) {
      onLoad()
      return
    }

    if (isAuthenticated) {
      dispatch(getUserStats())
    }
  }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Theme>
      {isPageLoading ? <GlobalLoader /> : <Router />}

      <UpgradeToProSuccessModal />
    </Theme>
  )
}

export default App
