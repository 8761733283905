import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  min-height: 260px;
  backdrop-filter: blur(25px);
  background-clip: padding-box;
  border: 1px solid
    ${(props) =>
      localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.geekblue4 : props.theme.colors.geekblue2};
  border-radius: 11px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 30px 20px;
  transition: 0.3s all;

  .icons-container {
    display: flex;
    align-items: center;
    gap: 5px;

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .language-icon,
      .interface-icon {
        width: 18px;
        position: absolute;
        filter: brightness(3);
      }
    }
  }

  > .title {
    color: ${(props) => props.theme.colors.gray13};
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0px;
  }

  .description {
    * {
      color: ${(props) => props.theme.colors.gray8};
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      margin: 0px;
    }
  }

  .hover-content {
    display: none;
    flex-direction: column;
    gap: 12px;
  }

  .libs-container {
    max-height: 105px;
    display: none;
    overflow: hidden;

    > .title {
      color: ${(props) => props.theme.colors.base.gray1};
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 6px;
    }

    .libs-box {
      max-height: 64px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 6px;
      overflow: auto;

      .lib {
        background-color: ${(props) => props.theme.colors.gray10};
        color: ${(props) => props.theme.colors.geekblue1};
        border: 1px solid ${(props) => props.theme.colors.base.geekblue5};
        border-radius: 2px;
        font-size: 12px;
        padding: 3px 6px;
      }
    }
  }

  .bottom-actions {
    display: none;
    width: 100%;
    justify-content: flex-end;

    .select-jumpbox-btn {
    }
  }

  ${(props) =>
    props.hasHover &&
    css`
      &:hover {
        background: linear-gradient(115deg, #1b0d42 12.66%, rgba(73, 80, 108, 0.53) 96.56%);
        justify-content: space-between;

        .icons-container,
        .description {
          display: none;
        }

        .title {
          color: ${(props) => props.theme.colors.base.gray1};
        }

        .hover-content {
          display: flex;
        }

        .libs-container {
          display: block;
        }

        .bottom-actions {
          display: flex;
        }
      }
    `}

  @media ${device.xlDown} {
    min-height: 184px;
    gap: 6px;
    padding: 20px;

    > .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }

    .description,
    .description * {
      font-size: 13px;
      line-height: 18px;
    }

    .libs-container {
      max-height: 78px;
    }

    .bottom-actions {
      .select-jumpbox-btn {
        height: 32px;
        font-size: 14px;
        line-height: 18px;
        padding: 4px 8px;
      }
    }
  }
`
