import Layout1 from '@/layouts/Layout1'
import NotFoundImage from '@/assets/images/not-found.svg'
import { Container } from './styles'

const NotFoundPage = ({ title, text, image }) => {
  return (
    <Layout1 navbar>
      <Container>
        <img src={image || NotFoundImage} alt={'Not found'} />

        <h3 className="title">{title || 'Oops! Page not found'}</h3>
        <p className="text-body">{text || 'The page you’re looking for doesn’t exist.'}</p>
      </Container>
    </Layout1>
  )
}

export default NotFoundPage
