// https://www.algolia.com/doc/api-reference/search-api-parameters/
const searchParameters = {
  attributesToRetrieve: ['*'],
  attributesToSnippet: ['*:20'],
  explain: ['*'],
  facets: ['*'],
  filters: "title.name:'Data Scientist with Python'",
  getRankingInfo: true,
  maxValuesPerFacet: 200,
  page: 0,
  hitsPerPage: 20,
  responseFields: ['*'],
  snippetEllipsisText: '…',
}

export default searchParameters
