import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: 32px;
  width: 102px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 9px;
  padding: 0px 6px;
  user-select: none;
  transition: all 0.5s;

  .timer-icon {
    width: 20px;
    height: 20px;
  }

  .visibility-button {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  .time {
    font-weight: 500;
    margin: 0px;

    &.clickeable {
      cursor: pointer;
    }
  }

  ${(props) =>
    props.isLarge &&
    css`
      height: 40px;

      .timer-icon {
        width: 30px;
        height: 30px;
      }

      .visibility-button {
        display: none;
      }

      .time {
        font-size: 24px;
        line-height: 32px;
      }
    `}

  ${(props) =>
    props.status === 'default' &&
    css`
      background: ${(props) => props.theme.colors.geekblue1};
      border: 1px solid ${(props) => props.theme.colors.geekblue1};

      .time {
        color: ${(props) => props.theme.colors.gray10};
      }

      .timer-icon,
      .visibility-button {
        color: ${(props) => props.theme.colors.gray6};
      }
    `}

  ${(props) =>
    props.status === 'info' &&
    css`
      background: linear-gradient(
        90deg,
        ${(props) => props.theme.colors.base.gray10} 20%,
        ${(props) => props.theme.colors.base.geekblue5} 120%
      );
      border: 1px solid ${(props) => props.theme.colors.geekblue5};

      .time {
        color: ${(props) => props.theme.colors.base.geekblue1};
      }

      .timer-icon,
      .visibility-button {
        color: ${(props) => props.theme.colors.base.geekblue3};
      }
    `}

  ${(props) =>
    props.status === 'danger' &&
    css`
      background: ${(props) => props.theme.colors.gray1};
      border: 1px solid ${(props) => props.theme.colors.base.red5};

      .time {
        color: ${(props) => props.theme.colors.base.red5};
      }

      .timer-icon,
      .visibility-button {
        color: ${(props) => props.theme.colors.base.red3};
      }
    `}
`
