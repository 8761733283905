import { useNavigate } from 'react-router-dom'
// import { useRouteError } from 'react-router-dom'
import Layout1 from '@/layouts/Layout1'
import ErrorCard from './components/ErrorCard'
import { Container } from './styles'

const ErrorPage = () => {
  const navigate = useNavigate()
  // const error = useRouteError()

  const refreshPage = () => {
    window.location.reload()
  }

  const goToDashboard = () => {
    navigate('/', { replace: true })
  }

  return (
    <Layout1 navbar>
      <Container>
        <ErrorCard
          title={'An unexpected error has occurred.'}
          // text={error.statusText || error.message}
          buttonText={'Refresh this page'}
          buttonOnClick={refreshPage}
          bottomText={'or go to Dashboard'}
          bottomOnClick={goToDashboard}
        />
      </Container>
    </Layout1>
  )
}

export default ErrorPage
