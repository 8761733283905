import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  > .header {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
    padding: 16px 24px;

    .title {
      font-weight: 500;
      margin: 0px;
    }
  }

  > .content {
    height: calc(100% - 57px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow: auto;
    padding: 24px;

    .versions-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .no-content {
        max-width: 260px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 16px;

        .image {
          width: 120px;
        }

        .text {
          color: ${(props) => props.theme.colors.gray7};
          font-weight: 400;
          text-align: center;
        }
      }

      .version-item {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
        padding: 10px 0px;

        .version-code {
          min-width: 40px;
          height: 40px;
          background-color: ${(props) => props.theme.colors.volcano2};
          color: ${(props) => props.theme.colors.volcano6};
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          text-transform: uppercase;
          border-radius: 50%;
          margin: 0px;
        }

        &.current {
          .version-code {
            background-color: ${(props) => props.theme.colors.green2};
            color: ${(props) => props.theme.colors.green6};
          }
        }

        .version-data {
          display: flex;
          flex-direction: column;
          gap: 2px;

          .date {
            margin: 0px;
          }

          .is-current-version {
            color: ${(props) => props.theme.colors.gray7};
            display: flex;
            align-items: center;
            gap: 6px;

            .icon {
              color: ${(props) => props.theme.colors.green5};
              font-size: 18px;
            }
          }
        }
      }
    }

    .unpublish-btn {
      margin-top: 20px;
    }
  }
`
