import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Button, Modal } from 'antd'
import { CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import { updatePlayground } from '@/store/playgrounds/actions'
import skillTrackComingSoonImg from '@/assets/images/skill-track-coming-soon.png'
import { Container } from './styles'

const VersionHistoryDrawerItem = () => {
  const dispatch = useDispatch()

  const { currentPlayground: playground, isLoading } = useSelector((state) => state.playgrounds)
  const hasVersions = !!playground?.versions?.length
  const isPublished = playground?.status === 'published'
  const isMemberCopy = playground?.metadata?.is_student_copy

  const renderVersions = () => {
    if (!hasVersions) {
      return (
        <div className="no-content">
          <img className="image" src={skillTrackComingSoonImg} />

          <h5 className="text">
            {isMemberCopy ? "You don't submitted for this project yet." : 'There is no version history available.'}
          </h5>
        </div>
      )
    }

    return [...(playground?.versions || [])]?.reverse()?.map((version, index) => (
      <div key={index} className={`version-item ${index === 0 && isPublished ? 'current' : ''}`}>
        <h5 className="version-code">{isMemberCopy ? version?.name?.replace('v', 's') : version?.name}</h5>

        <div className="version-data">
          <p className="date">{dayjs(version?.created).format('LLL')}</p>

          {index === 0 && isPublished && (
            <div className="is-current-version">
              <CheckCircleFilled className="icon" />{' '}
              <span>Last {isMemberCopy ? 'submission' : 'published version'}</span>
            </div>
          )}
        </div>
      </div>
    ))
  }

  const unpublish = () => {
    Modal.confirm({
      title: 'Unpublish playground',
      content:
        'Are you sure you want to unpublish this Playground? It will become draft and only you will be able to access it.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, unpublish',
      cancelText: 'Keep working',
      onOk: () => dispatch(updatePlayground(playground?.id, { status: 'draft' })),
      okButtonProps: { danger: true, type: 'primary', disabled: isLoading, loading: isLoading },
    })
  }

  return (
    <Container className="version-history-drawer-item">
      <div className="header">
        <h5 className="title">{isMemberCopy ? 'Submissions' : 'Version history'}</h5>
      </div>

      <div className="content">
        <div className="versions-container">{renderVersions()}</div>

        {!isMemberCopy && hasVersions && isPublished && (
          <Button
            className="unpublish-btn"
            type="primary"
            danger
            onClick={unpublish}
            loading={isLoading}
            disabled={isLoading}
          >
            Unpublish
          </Button>
        )}
      </div>
    </Container>
  )
}

export default VersionHistoryDrawerItem
