import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;
  width: 0px;
  background-color: ${(props) => props.theme.colors.gray1};
  border-left: 2px solid ${(props) => props.theme.colors.gray3};
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  transition: width 0.3s;
  z-index: 2;

  ${(props) =>
    props.hasContent &&
    css`
      max-width: 520px;
      min-width: 520px;
      box-shadow: 0px 2px 8px 0px
        ${localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray7 : props.theme.colors.gray13}26;
    `}

  .close-btn {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 2px;

    .icon {
      line-height: inherit;

      svg {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 18px;
      }
    }
  }

  @media ${device.xlDown} {
    position: absolute;

    ${(props) =>
      props.hasContent &&
      css`
        box-shadow: 300px 2px 300px 300px
          ${localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray7 : props.theme.colors.gray13}80;
      `}
  }

  @media ${device.mdDown} {
    ${(props) =>
      props.hasContent &&
      css`
        max-width: 100%;
        min-width: 100%;
      `}
  }
`
