import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Tooltip } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Comment24Regular } from '@fluentui/react-icons'
import getIconByUrl from '@/utils/getIconByUrl'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import Button from '@/components/Button'
import { createPlayground } from '@/store/playgrounds/actions'
import { Container } from './styles'

const CustomProjectCard = ({ accountId, customProject }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [mainIcon, setMainIcon] = useState(null)

  const { userProfile } = useSelector((state) => state.users)
  const { isLoading } = useSelector((state) => state.playgrounds)

  const isPlaygroundOwner = userProfile?.id === customProject?.user_id
  const isModule = customProject?.type === 'module'
  const isPlayground = customProject?.type === 'playground'

  const renderActions = () => {
    let infoText
    let customProjectUrl = null
    let buttonText = 'Start assignment'
    let buttonType = 'default'
    let buttonIcon = null
    let buttonOnClick = null

    if (isModule) {
      customProjectUrl = `/project/${customProject?.id}`

      if (!customProject?.scoring) {
        // buttonText = 'Start assignment'
        buttonType = 'primary'
      }

      if (customProject?.scoring > 0 && customProject?.scoring < 1) {
        const lastModifiedAtFrom = dayjs(customProject?.last_activity_at).from()
        infoText = customProject?.last_activity_at && `Last modified ${lastModifiedAtFrom}`
        buttonText = 'Continue assignment'
      }

      if (customProject?.scoring === 1) {
        const lastModifiedAtFrom = dayjs(customProject?.finished_at).from()
        infoText = customProject?.finished_at && `Finished ${lastModifiedAtFrom}`
        buttonText = 'Review assignment'
        buttonIcon = <CheckCircleOutlined />
      }
    }

    if (isPlayground) {
      customProjectUrl = `/custom-projects/${accountId}/${customProject?.id}`

      if (!isPlaygroundOwner) {
        // buttonText = 'Start assignment'
        buttonType = 'primary'
        const auxCustomProject = {
          playground_id: customProject?.id,
          name: customProject?.name,
          metadata: {
            account_id: accountId,
            is_student_copy: true,
          },
          visibility: 'private',
        }
        buttonOnClick = () =>
          dispatch(
            createPlayground(auxCustomProject, (p) =>
              navigate(`/custom-projects/${accountId}/${p?.id}?mode=edit`, { replace: true }),
            ),
          )
      }

      if (isPlaygroundOwner && !customProject?.versions_count) {
        const createdAtFrom = dayjs(customProject?.created).from()
        infoText = customProject?.created && `Started ${createdAtFrom}`
        customProjectUrl += '?mode=edit'
        buttonText = 'Continue assignment'
      }

      if (isPlaygroundOwner && customProject?.versions_count) {
        const lastModifiedAtFrom = dayjs(customProject?.last_submitted_at).from()
        infoText = customProject?.last_submitted_at && `Submitted ${lastModifiedAtFrom}`
        buttonText = 'Review assignment'
        buttonIcon = <CheckCircleOutlined />
      }
    }

    return (
      <div className="actions">
        {infoText && <p className="text">{infoText}</p>}

        <Link className="link" to={buttonOnClick ? '#' : customProjectUrl}>
          <Button
            className="cta-button"
            type={buttonType}
            icon={buttonIcon}
            onClick={buttonOnClick}
            loading={isLoading}
          >
            {buttonText}
          </Button>
        </Link>
      </div>
    )
  }

  useEffect(() => {
    if (!isPlayground || customProject?.image_url) return

    getIconByUrl({
      iconUrl: 'ant-BuildOutlined',
      className: 'main-icon',
      onReady: setMainIcon,
    })
  }, [isPlayground])

  return (
    <Container
      className="custom-project-card"
      isModule={isModule}
      isPlayground={isPlayground}
      playgroundBackgroundImage={customProject?.image_url}
    >
      <div className="img-container">
        {isModule && renderModuleImage(customProject)}
        {isPlayground && mainIcon}
      </div>

      <div className="project-info">
        <h5 className="title">{customProject?.name}</h5>

        {renderActions()}
      </div>

      {!!customProject?.comments_count && (
        <Tooltip
          title={`You have ${customProject?.comments_count} comment${customProject?.comments_count > 1 ? 's' : ''} on this project`}
        >
          <div className="comments-info">
            <Comment24Regular />
          </div>
        </Tooltip>
      )}
    </Container>
  )
}

export default CustomProjectCard
