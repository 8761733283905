import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button as AntButton } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import Button from '@/components/Button'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { stopModuleAttempt } from '@/store/modules/actions'
import { deallocateLab } from '@/store/labs/actions'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Modal } from './styles'

const LabDeallocateButton = ({ labId, labSessionId, moduleAttemptId, text, disabled, type, callback }) => {
  const dispatch = useDispatch()

  const { uiConfig } = useSelector((state) => state.app)
  const { userProfile } = useSelector((state) => state.users)
  const { currentModule } = useSelector((state) => state.modules)
  const { currentPlayground } = useSelector((state) => state.playgrounds)

  const isCustomProject = !!currentPlayground?.metadata?.account_id
  const isModule = !!currentModule?.id

  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const canResumeLab = userProfile?.permissions?.includes('labs.resume_lab')

  const handleStopLab = async () => {
    setIsLoading(true)

    if (isModule) {
      dispatch(stopModuleAttempt(currentModule?.id, moduleAttemptId, { reason: 'manual' }))
    } else {
      await dispatch(deallocateLab({ labId, labSessionId, deallocateReason: 'manual' }))
    }

    setIsLoading(false)

    if (callback) {
      callback()
    }

    setIsModalVisible(false)
  }

  const handleOpenUpgradeToProModal = () => {
    setIsModalVisible(false)
    dispatch(toggleUpgradeToProModal(true))
  }

  return (
    <>
      <AntButton
        id="lab-stop-btn"
        type={type || 'primary'}
        danger
        className="stop-btn"
        onClick={() => setIsModalVisible(true)}
        disabled={disabled}
      >
        {text || 'Stop lab'}
      </AntButton>

      <Modal
        className="stop-lab-confirmation-modal"
        title={`Are you sure you want to stop ${isModule || isCustomProject ? 'project' : 'playground'}?`}
        open={isModalVisible}
        onOk={handleStopLab}
        onCancel={() => (isLoading ? null : setIsModalVisible(false))}
        okText={`Stop ${isModule || isCustomProject ? 'project' : 'playground'}`}
        cancelText="Keep working"
        okButtonProps={{ danger: true, disabled: isLoading, loading: isLoading, className: 'confirm-btn' }}
      >
        <p className="subtitle">
          <WarningFilled className="icon" />{' '}
          {isModule
            ? 'Stopping the project will shut down your lab and prevent you from submitting new activities.'
            : `Stopping the ${isCustomProject ? 'project' : 'playground'} will shut down your interactive lab environment.`}
        </p>

        {uiConfig?.showUpgradeToProUi && !canResumeLab && isModule && (
          <div className="pro-info">
            <p className="pro-simple-text">
              PRO users enjoy unlimited time to resolve projects, and the ability to resume them once stopped.
            </p>

            <Button type="primary" ispro="true" onClick={handleOpenUpgradeToProModal}>
              <img className="pro-badge" src={ProBadge} alt="Update to PRO" />
              Upgrade Now
            </Button>
          </div>
        )}
      </Modal>
    </>
  )
}

export default LabDeallocateButton
