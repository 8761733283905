import styled, { css, keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  overflow: auto;
  padding: 10px 30px 20px;
  margin-bottom: ${(props) => (props.extraBottomMargin ? '180px' : '0px')};

  .module-content {
    animation: 1s ${fadeInAnimation};

    p {
      margin-bottom: 0px;

      &.link span,
      &.link a {
        color: ${(props) => props.theme.colors.gray10};
        text-decoration: underline;
        cursor: pointer;
      }
    }

    hr {
      border-color: ${(props) => props.theme.colors.gray4};
      margin: 0px 0px 20px;
    }

    .rate {
      margin: 8px 0px;

      svg {
        font-size: 20px;
      }
    }

    .modules-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .loading-container {
      .ant-skeleton {
        margin: 0px;

        .ant-skeleton-paragraph {
          margin: 4px 0px;

          li {
            height: 102px;
            width: 100% !important;
            border-radius: 16px;
          }
        }
      }
    }

    .see-all-modules-container {
      text-align: center;
      position: relative;

      &.has-more-modules {
        background: linear-gradient(transparent, ${(props) => props.theme.colors.gray2} 60%);
        padding-top: 100px;
        margin-top: -130px;
      }

      .link {
        margin-top: 10px;
      }
    }
  }

  .floating-rating-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
      props.extraBottomMargin
        ? css`
            position: absolute;
            left: 0px;
            bottom: 62px;
          `
        : css`
            position: relative;
            margin-top: 30px;
          `}

    .floating-box {
      max-width: 320px;
      background: linear-gradient(
        180deg,
        ${(props) => props.theme.colors.blue1} 0%,
        ${(props) => props.theme.colors.gray1} 40%
      );
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      border-radius: 10px;
      padding: 18px 38px;
      box-shadow:
        0px 3px 6px -4px rgba(0, 0, 0, 0.12),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 9px 28px 8px rgba(0, 0, 0, 0.05);

      .title {
        margin: 0px;
      }

      .ant-spin {
        margin: 5px;
      }

      .text {
        color: ${(props) => props.theme.colors.gray8};
        margin: 0px;
      }

      .link {
        color: ${(props) => props.theme.colors.gray10};
        text-decoration: underline;
        cursor: pointer;
        margin: 0px;
      }

      .rate {
        margin: 4px 0px;

        svg {
          font-size: 20px;
        }
      }
    }
  }
`
