import algoliasearch from 'algoliasearch'
import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const client = algoliasearch(
  import.meta.env.REACT_APP_ALGOLIA_APP_ID,
  import.meta.env.REACT_APP_ALGOLIA_SEARCH_ONLY_KEY,
)
const index = client.initIndex(import.meta.env.REACT_APP_ALGOLIA_DATASOURCES_INDEX)

const fetchAlgoliaDataSources = async (filter) => {
  return index
    .search('', {
      filters: filter,
      hitsPerPage: 100,
    })
    .then((response) => {
      const auxData = response?.hits?.map((item) => ({
        ...item,
        id: item?.objectID,
      }))

      return Promise.resolve(auxData)
    })
    .catch((error) => Promise.reject(apiErrorHandler(error)))
}

const fetchDataSource = async (dataSourceId) => {
  const route = `data-sources/${dataSourceId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { fetchAlgoliaDataSources, fetchDataSource }
