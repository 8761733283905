import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const fetchLab = async (labId) => {
  const route = `labs/${labId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postLabAllocate = async (labId, data) => {
  const route = `labs/${labId}/sessions/allocate`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postLabDeallocate = async (data = {}) => {
  const { labId, labSessionId, deallocateReason } = data
  const route = `labs/${labId}/sessions/${labSessionId}/deallocate`
  const body = {
    deallocate_reason: deallocateReason,
  }
  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postLabPing = async (labId, sessionId, data) => {
  const route = `labs/${labId}/sessions/${sessionId}/ping`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postLabPullImages = async (labId) => {
  const route = `labs/${labId}/pull-images`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const getLabDebug = async (labId, userId) => {
  const route = `labs/${labId}/debug?user_id=${userId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchSession = async (labSessionId) => {
  const route = `sessions/${labSessionId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postSessionStart = async (labSessionId, deviceId) => {
  const route = `sessions/${labSessionId}/devices/${deviceId}/start`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postSessionStop = async (labSessionId, deviceId) => {
  const route = `sessions/${labSessionId}/devices/${deviceId}/stop`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postSessionRestart = async (labSessionId, deviceId) => {
  const route = `sessions/${labSessionId}/devices/${deviceId}/restart`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export {
  // LAB
  fetchLab,
  postLabAllocate,
  postLabDeallocate,
  postLabPing,
  postLabPullImages,
  getLabDebug,
  // LAB SESSION
  fetchSession,
  postSessionStart,
  postSessionStop,
  postSessionRestart,
}
